<template>
  <div class="manageCategories">
    <h2 class="section_title">{{ $t("AdminPage.ManageCategories.Title") }}</h2>
    <!--================= Manage Button =================-->
    <div class="manage">
      <div class="add_category">
        <button class="add_category_btn" v-on:click="showAddPopup()">
          {{ $t("AdminPage.ManageCategories.ManageBtn1") }}
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="search">
        <input
          type="text"
          class="search_input"
          @keydown.enter="searchCategory()"
          v-model="query"
          :placeholder="$t('AdminPage.ManageCategories.Search')"
        />
        <button class="search_btn" v-on:click="searchCategory()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!--================= Manage Button =================-->
    <!--================= Display Categories =================-->
    <div class="displayCategories">
      <div class="categories">
        <div
          class="category"
          v-for="category in Categories"
          v-bind:key="category.id"
        >
          <div class="category_icon">
            <div class="c_i">
              <i :class="category.icon"></i>
            </div>
            <div class="c_n">
              <h3>{{ category.name }}</h3>
            </div>
          </div>
          <div class="category_info">
            <div class="category_info_box">
              <!-- <button class="view_category_btn">
                <i class="fa-regular fa-eye"></i>
              </button> -->
              <button
                class="view_category_btn"
                v-on:click="showEditPopup(category.id)"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button
                class="view_category_btn"
                v-on:click="deleteCategory(category.id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================= Display Categories =================-->
    <!--================= Add Category Popup =================-->
    <div id="AddCategory" v-if="showAdd">
      <div class="dispage">
        <div class="AddC">
          <div class="close">
            <button class="exit_button" v-on:click="closeAddPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form @submit.prevent="addCategory()" method="post">
              <h2 style="color: #eee; text-align: center">
                {{ $t("AdminPage.ManageCategories.AddPopupTitle") }}
              </h2>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="CategoryNameAR"
                    class="name-input"
                    id="Add_CategoryNameAR_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder1')"
                  />
                  <br />
                  <label
                    for="name"
                    class="Add_CategoryNameAR_input_label"
                  ></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="CategoryNameEN"
                    class="name-input"
                    id="Add_CategoryNameEN_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder2')"
                  />
                  <br />
                  <label
                    for="name"
                    class="Add_CategoryNameEN_input_label"
                  ></label>
                </div>
              </div>
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="Icon"
                    class="name-input"
                    id="Add_Icon_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder3')"
                  />
                  <br />
                  <label for="name" class="Add_Icon_input_label"></label>
                </div>
              </div>
              <div class="input">
                <button class="submit">
                  {{ $t("AdminPage.ManageCategories.Btn1") }}
                </button>
              </div>
              <div class="input" id="success_msg">
                <label for="input" class="success-msg-1"></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--================= Add Category Popup =================-->
    <!--================= Edit Category Popup =================-->
    <div id="EditCategory" v-if="showEdit">
      <div class="dispage">
        <div class="EditC">
          <div class="close">
            <button class="exit_button" v-on:click="closeEditPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <form @submit.prevent="editCategory()" method="post">
              <h2 style="color: #eee; text-align: center">
                {{ $t("AdminPage.ManageCategories.EditPopupTitle") }}
              </h2>
              <!--=================== Arabic Category Name ===================-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="ECategoryNameAR"
                    class="name-input"
                    id="Edit_CategoryNameAR_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder1')"
                  />
                  <br />
                  <label
                    for="name"
                    class="Edit_CategoryNameAR_input_label"
                  ></label>
                </div>
              </div>
              <!--=================== Arabic Category Name ===================-->
              <!--=================== English Category Name ===================-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="ECategoryNameEN"
                    class="name-input"
                    id="Edit_CategoryNameEN_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder2')"
                  />
                  <br />
                  <label
                    for="name"
                    class="Edit_CategoryNameEN_input_label"
                  ></label>
                </div>
              </div>
              <!--=================== English Category Name ===================-->
              <!--=================== Icon ===================-->
              <div class="input">
                <div>
                  <input
                    type="text"
                    name="name"
                    autocomplete="off"
                    v-model="EIcon"
                    class="name-input"
                    id="Edit_Icon_input"
                    :placeholder="$t('AdminPage.ManageCategories.placeholder3')"
                  />
                  <br />
                  <label for="name" class="Edit_Icon_input_label"></label>
                </div>
              </div>
              <!--=================== Icon ===================-->

              <div class="input">
                <button class="submit">
                  {{ $t("AdminPage.ManageCategories.Btn2") }}
                </button>
              </div>

              <div class="input" id="success_msg">
                <label for="input" class="success-msg-2"></label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--================= Edit Category Popup =================-->
    <!--============= Message box Section =============-->
    <div class="Message1" id="MessageBox">
      <div class="message_contaier">
        <div class="MessContent">
          <p id="msg"></p>
        </div>
        <div class="closeMsg">
          <button class="closeBtn" v-on:click="closeMsg()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <!--============= Message box Section =============-->
  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
import ApiUrl from "../../ApiUrl";
let $ = jquery;
export default {
  data() {
    return {
      showAdd: false,
      showEdit: false,
      Categories: [],
      CategoryNameAR: "",
      CategoryNameEN: "",
      Icon: "",
      categoryId: "",
      ECategoryNameAR: "",
      ECategoryNameEN: "",
      EIcon: "",
      query: "",
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let msg_box = document.getElementById("MessageBox");
    let page = document.getElementsByClassName("manageCategories")[0];
    let section_title = document.getElementsByClassName("section_title");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      msg_box.classList.add("Message1R");
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    } else {
      msg_box.classList.add("Message1L");
    }
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      await axios
        .get(ApiUrl.url + "/api/Categories/", {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.Categories = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showAddPopup() {
      this.showAdd = true;
      setTimeout(() => {
        $("#AddCategory").fadeIn(200);
      }, 0);
    },
    closeAddPopup() {
      $("#AddCategory").fadeOut(200);
      setTimeout(() => {
        this.showAdd = false;
      }, 1000);
    },
    addCategory() {
      $("#Add_CategoryNameAR_input").click(function () {
        window.onkeydown = function () {
          $("#Add_CategoryNameAR_input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".Add_CategoryNameAR_input_label").text("");
        };
      });

      $("#Add_CategoryNameEN_input").click(function () {
        window.onkeydown = function () {
          $("#Add_CategoryNameEN_input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".Add_CategoryNameEN_input_label").text("");
        };
      });

      $("#Add_Icon_input").click(function () {
        window.onkeydown = function () {
          $("#Add_Icon_input").css("borderBottom", "3px solid #472bff");
          $(".Add_Icon_input_label").text("");
        };
      });

      if (
        this.CategoryNameAR == "" &&
        this.CategoryNameEN == "" &&
        this.Icon == ""
      ) {
        $("#Add_CategoryNameAR_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Add_CategoryNameAR_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err1")
        );

        $("#Add_CategoryNameEN_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Add_CategoryNameEN_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err2")
        );

        $("#Add_Icon_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Add_Icon_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err3")
        );

        return;
      }

      if (this.CategoryNameAR == "") {
        $("#Add_CategoryNameAR_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Add_CategoryNameAR_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err1")
        );
        return;
      }

      if (this.CategoryNameEN == "") {
        $("#Add_CategoryNameEN_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Add_CategoryNameEN_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err2")
        );
        return;
      }

      if (this.Icon == "") {
        $("#Add_Icon_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Add_Icon_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err3")
        );
        return;
      }

      axios
        .post(
          ApiUrl.url + "/api/Categories/",
          {
            englishname: this.CategoryNameEN,
            arabicname: this.CategoryNameAR,
            icon: this.Icon,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".success-msg-1").text(
              this.$i18n.t("AdminPage.ManageCategories.successMsg1")
            );
            this.loadCategories();
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    },
    showEditPopup(categoryId) {
      this.categoryId = categoryId;
      axios
        .get(ApiUrl.url + `/api/Categories/${categoryId}`)
        .then(async (res) => {
          this.ECategoryNameAR = await res.data.arabicName;
          this.ECategoryNameEN = await res.data.englishName;
          this.EIcon = await res.data.icon;
          this.showEdit = true;
          setTimeout(() => {
            $("#EditCategory").fadeIn(200);
          }, 0);
        });
    },
    closeEditPopup() {
      $("#EditCategory").fadeOut(200);
      setTimeout(() => {
        this.showEdit = false;
      }, 1000);
    },
    editCategory() {
      $("#Edit_CategoryNameAR_input").click(function () {
        window.onkeydown = function () {
          $("#Edit_CategoryNameAR_input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".Edit_CategoryNameAR_input_label").text("");
        };
      });

      $("#Edit_CategoryNameEN_input").click(function () {
        window.onkeydown = function () {
          $("#Edit_CategoryNameEN_input").css(
            "borderBottom",
            "3px solid #472bff"
          );
          $(".Edit_CategoryNameEN_input_label").text("");
        };
      });

      $("#Edit_Icon_input").click(function () {
        window.onkeydown = function () {
          $("#Edit_Icon_input").css("borderBottom", "3px solid #472bff");
          $(".Edit_Icon_input_label").text("");
        };
      });

      if (
        this.ECategoryNameAR == "" &&
        this.ECategoryNameEN == "" &&
        this.EIcon == ""
      ) {
        $("#Edit_CategoryNameAR_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Edit_CategoryNameAR_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err1")
        );

        $("#Edit_CategoryNameEN_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Edit_CategoryNameEN_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err2")
        );

        $("#Edit_Icon_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Edit_Icon_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err3")
        );

        return;
      }

      if (this.ECategoryNameAR == "") {
        $("#Edit_CategoryNameAR_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Edit_CategoryNameAR_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err1")
        );
        return;
      }

      if (this.ECategoryNameEN == "") {
        $("#Edit_CategoryNameEN_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".Edit_CategoryNameEN_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err2")
        );
        return;
      }

      if (this.EIcon == "") {
        $("#Edit_Icon_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".Edit_Icon_input_label").text(
          this.$i18n.t("AdminPage.ManageCategories.err3")
        );
        return;
      }

      axios
        .put(
          ApiUrl.url + `/api/Categories/${this.categoryId}`,
          {
            englishname: this.ECategoryNameEN,
            arabicname: this.ECategoryNameAR,
            icon: this.EIcon,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".success-msg-2").text(
              this.$i18n.t("AdminPage.ManageCategories.successMsg2")
            );
            this.loadCategories();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchCategory() {
      axios
        .get(ApiUrl.url + `/api/Categories/Search?query=${this.query}`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    deleteCategory(categoryId) {
      axios
        .delete(ApiUrl.url + `/api/Categories/${categoryId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < this.Categories.length; i++) {
              if (this.Categories[i].id == categoryId) {
                this.Categories.splice(i, 1);
              }
            }
            $("#msg").text(this.$t("AdminPage.ManageCategories.deleteSuccess"));
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err.response.data == "Category has products!") {
            $("#msg").text(this.$t("AdminPage.ManageCategories.deleteErr"));
            $(".Message1").fadeIn(200);
            setTimeout(() => {
              $(".Message1").fadeOut(400);
            }, 3000);
          }
        });
    },
    closeMsg() {
      $(".Message1").fadeOut(400);
    },
  },
};
</script>

<style lang="scss" scoped>
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}
/*********************** Manage Button ***********************/
.manage {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.add_category_btn {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3b24d2;
  }
}
.search {
  display: flex;
  align-items: center;
}
.search_input {
  width: 365px;
  height: 40px;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
.search_input:focus {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.search_btn {
  width: 40px;
  height: 40px;
  margin: 1rem;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.search_input::placeholder {
  color: #1f2833;
}
/*********************** Manage Button ***********************/
/*********************** Add Category Popup ***********************/
#AddCategory,
#EditCategory {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddC {
  width: 600px;
  height: 550px;
  background: #1f2833;
  border-radius: 25px;
}
.EditC {
  width: 600px;
  height: 550px;
  background: #1f2833;
  border-radius: 25px;
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #eeeeee;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  display: flex;
  justify-content: center;
  width: 100%;
}
.input {
  padding: 20px;
}
.Add_CategoryNameAR_input_label,
.Add_CategoryNameEN_input_label,
.Add_Icon_input_label {
  color: rgb(250, 82, 82);
}
::placeholder {
  color: #eeeeee;
}
.name-input {
  background: none;
  border: none;
  outline: none;
  width: 365px;
  height: 40px;
  font-size: 15px;
  color: #eeeeee;
  border-bottom: 3px solid #472bff;
}

.submit {
  width: 365px;
  height: 40px;
  padding: 6px;
  font-size: 15px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #472bff;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.submit:hover {
  cursor: pointer;
  background-color: #3721c5;
}
#success_msg {
  text-align: center;
}
.success-msg-1 {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.Edit_CategoryNameAR_input_label,
.Edit_CategoryNameEN_input_label,
.Edit_Icon_input_label {
  color: rgb(250, 82, 82);
}

/*********************** Add Category Popup ***********************/
/*********************** Display Categories ***********************/
.displayCategories {
  display: flex;
  justify-content: center;
  padding: 2.5rem;
}
.categories {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.category {
  width: 300px;
  height: 100px;
  transition: all 300ms ease-in-out;
}
.category_icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 3px solid#472bff;
  transition: all 300ms ease-in-out;
}
.c_i {
  display: flex;
  justify-content: center;
  width: 50px;
  padding: 20px;
  i {
    font-size: 24px;
    color: #472bff;
  }
}
.c_n {
  display: flex;
  justify-content: center;
  width: 250px;
}
.category_info {
  background-color: rgba(232, 232, 232, 0.637);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transform: translateY(-100px);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category:hover {
  .category_info {
    opacity: 1;
  }
}
.view_category_btn {
  margin: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #fff;
    color: #472bff;
  }
}
@media screen and (max-width: 1305px) {
  .categories {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 975px) {
  .categories {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 645px) {
  .categories {
    grid-template-columns: auto;
  }
}
/*********************** Display Categories ***********************/
/*********************** Message box ***********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  border-left: 10px solid #472bff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.Message1R {
  right: 2%;
}
.Message1L {
  left: 2%;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #472bff;
  }
}
/*********************** Message box ***********************/
</style>
