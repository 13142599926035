<template>
  <div class="updatePassword">
    <!--================= Form =================-->
    <div class="form">
      <div>
        <h2>{{ $t("ProfilePage.UserSettings.UpdatePasswordPage.title") }}</h2>
        <div class="input" v-if="!isPasswordValid">
          <input
            type="password"
            id="old_password_input"
            :placeholder="
              $t('ProfilePage.UserSettings.UpdatePasswordPage.placeholder1')
            "
            class="text-input"
            v-model="oldPassword"
          />
          <br />
          <label
            for="old_password_input"
            class="old_password_input_label"
          ></label>
        </div>
        <div class="input" v-if="isPasswordValid">
          <input
            type="password"
            id="new_password_input"
            :placeholder="
              $t('ProfilePage.UserSettings.UpdatePasswordPage.placeholder2')
            "
            class="text-input"
            v-model="newPassword"
          />
          <br />
          <label
            for="new_password_input"
            class="new_password_input_label"
          ></label>
        </div>
        <div class="input" v-if="isPasswordValid">
          <input
            type="password"
            id="confirm_password_input"
            :placeholder="
              $t('ProfilePage.UserSettings.UpdatePasswordPage.placeholder3')
            "
            class="text-input"
            v-model="confirmPassword"
          />
          <br />
          <label
            for="confirm_password_input"
            class="confirm_password_input_label"
          ></label>
        </div>
        <div class="input" v-if="!isPasswordValid">
          <button class="update_password_btn" v-on:click="updatePassword()">
            {{ $t("ProfilePage.UserSettings.UpdatePasswordPage.checkBtn") }}
          </button>
        </div>
        <div class="input" v-if="isPasswordValid" v-on:click="upPassword()">
          <button class="update_password_btn">
            {{ $t("ProfilePage.UserSettings.UpdatePasswordPage.UpdateBtn") }}
          </button>
        </div>
        <div class="input center">
          <span class="message"></span>
        </div>
      </div>
    </div>
    <!--================= Form =================-->
  </div>
</template>

<script>
import jquery from "jquery";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
let $ = jquery;
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isPasswordValid: false,
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("updatePassword")[0];
      page.style.direction = "ltr";
    }
  },
  methods: {
    async updatePassword() {
      $("#old_password_input").click(function () {
        window.onkeypress = function () {
          $("#old_password_input").css("borderBottom", "3px solid #472bff");
          $(".old_password_input_label").text("");
        };
      });

      if (this.oldPassword == "") {
        $("#old_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".old_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err1")
        );
        return;
      }
      await axios
        .post(
          ApiUrl.url + "/api/User/checkCurrentPassword",
          { password: this.oldPassword },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.isPasswordValid = true;
          }
        })
        .catch((err) => {
          if (err.response.data == "Wrong Password") {
            $("#old_password_input").css(
              "borderBottom",
              "3px solid rgb(250,82,82)"
            );
            $(".old_password_input_label").text(
              this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err5")
            );
          }
        });
    },
    async upPassword() {
      $("#new_password_input").click(function () {
        window.onkeypress = function () {
          $("#new_password_input").css("borderBottom", "3px solid #472bff");
          $(".new_password_input_label").text("");
        };
      });

      $("#confirm_password_input").click(function () {
        window.onkeypress = function () {
          $("#confirm_password_input").css("borderBottom", "3px solid #472bff");
          $(".confirm_password_input_label").text("");
        };
      });

      if (this.newPassword == "" && this.confirmPassword == "") {
        $("#new_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err2")
        );
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err3")
        );
        return;
      }
      if (this.newPassword == "") {
        $("#new_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err2")
        );
        return;
      }

      if (this.confirmPassword == "") {
        $("#confirm_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".confirm_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err3")
        );
        return;
      }

      if (this.newPassword != this.confirmPassword) {
        $("#new_password_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_password_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdatePasswordPage.err4")
        );
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/api/User/updatePassword",
          { newPassword: this.newPassword },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".message").text(
              this.$i18n.t(
                "ProfilePage.UserSettings.UpdatePasswordPage.success1"
              )
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.updatePassword {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
/*********************** Form ***********************/
.form {
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }
}
.input {
  padding: 1rem;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  border-bottom: 3px solid #472bff;
  transition: all 300ms ease-in-out;
}
.update_password_btn {
  width: 365px;
  height: 40px;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  font-size: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.old_password_input_label,
.new_password_input_label,
.confirm_password_input_label {
  color: rgb(250, 82, 82);
}
.center {
  text-align: center;
}
</style>
