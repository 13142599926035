<template>
  <div class="shop">
    <div class="flex-p">
      <!--================= Header =================-->
      <div class="nav">
        <NavBar />
      </div>
      <!--================= Header =================-->

      <div class="content">
        <!--================= Sidebar =================-->
        <div class="sidebar">
          <div class="sidebar_item1">
            <h2 class="sidebar_item1_title">{{ $t("ShopPage.Categories") }}</h2>
            <div class="dropdown">
              <h3 class="dropdown_title" v-on:click="openDropdown()">
                {{ $t("ShopPage.selectaC") }}
              </h3>
              <div
                class="items"
                v-for="category in categories"
                v-bind:key="category.id"
              >
                <div class="item" v-on:click="selectCategory(category)">
                  {{ category.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="sidebar_item1">
            <h2 class="sidebar_item1_title">{{ $t("ShopPage.PriceRange") }}</h2>
            <div class="price_range">
              <div class="from">
                <h3>{{ $t("ShopPage.from") }}</h3>
                <input type="text" class="price_range_input" />
              </div>
              <div class="to">
                <h3>{{ $t("ShopPage.to") }}</h3>
                <input type="text" class="price_range_input" />
              </div>
            </div>
          </div>
        </div>
        <!--================= Sidebar =================-->

        <div class="content-c">
          <!--================= Info =================-->
          <div class="head">
            <div class="search">
              <div>
                <input
                  type="text"
                  :placeholder="$t('ShopPage.Search')"
                  @keydown.enter="search()"
                  v-model="query"
                  class="search_input"
                />
                <button class="search_btn" v-on:click="search()">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
            <h1>{{ $t("ShopPage.Products") }}</h1>
            <span>{{ $t("ShopPage.Products") }} : {{ products.length }}</span>
            <div class="selected_categories">
              {{ $t("ShopPage.selectedC") }}:
              <div
                class="selected_category"
                v-for="category in filterCategory"
                v-bind:key="category.id"
              >
                {{ category.name }}
                <button
                  class="remove_selected_category"
                  v-on:click="removeCategoryFromFilterCategory(category)"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
          </div>
          <!--================= Info =================-->
          <!--================= Products =================-->
          <div class="box">
            <div class="products">
              <div class="pro-wrap" v-if="!loadingProducts">
                <div
                  class="pro"
                  v-for="product in products"
                  v-bind:key="product.id"
                >
                  <button
                    v-on:click="addToCart(product.id)"
                    class="add_to_shopping_cart"
                  >
                    <i class="fa-solid fa-cart-shopping"></i>
                  </button>
                  <div class="discount_rate" v-if="product.isDiscounted">
                    {{ $t("ShopPage.Sales") }} : {{ product.discountPercent }} %
                  </div>
                  <div class="discount_rate" v-else>
                    {{ $t("ShopPage.NoSales") }}
                  </div>
                  <div class="product">
                    <router-link
                      :to="`/${$i18n.locale}/Product/${product.id}`"
                      class="product_link"
                    >
                      <div class="product_img">
                        <img :src="product.image" />
                      </div>
                      <div class="product_info">
                        <div class="product_name">{{ product.name }}</div>
                        <div class="price" v-if="product.isDiscounted">
                          {{
                            Math.round(
                              product.price -
                                (product.discountPercent / 100) * product.price
                            )
                          }}
                          ₪
                        </div>
                        <div class="price" v-else>{{ product.price }} ₪</div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="loader" v-if="loadingProducts"></div>
              <div class="noResults" v-if="NoResults">
                {{ $t("ShopPage.noResults") }}
              </div>
            </div>
          </div>
          <!--================= Products =================-->
        </div>
      </div>
    </div>

    <!--================= Message Box =================-->
    <div class="Message1" id="MessageBox">
      <div class="message_contaier">
        <div class="MessContent">
          <p id="msg"></p>
        </div>
        <div class="closeMsg">
          <button class="closeBtn" v-on:click="closeMsg()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <!--================= Login/Signup form =================-->
    <div id="LoginSingupForms" v-if="showLoginSingup">
      <div class="dispage">
        <div class="popupContainer">
          <div class="close">
            <button class="exit_button" v-on:click="closeLoginSignPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <div>
              <div class="input">
                <h1 class="wel_msg">{{ $t("LoginPage.Welcome") }}</h1>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("LoginPage.phoneNumber")
                }}</label>
                <br />
                <input
                  type="text"
                  class="text-input"
                  id="phonenumber_input"
                  v-model="phoneNumber"
                  :placeholder="$t('LoginPage.phoneNumber')"
                />
                <br />
                <label class="input_err" id="phonenumber_err"></label>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("LoginPage.Password")
                }}</label>
                <br />
                <input
                  type="password"
                  class="text-input"
                  id="password_input"
                  v-model="password"
                  :placeholder="$t('LoginPage.Password')"
                />
                <br />
                <label class="input_err" id="password_err"></label>
              </div>
              <div class="input">
                <button type="submit" class="btn" v-on:click="Login()">
                  {{ $t("LoginPage.Login") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================= Login/Signup form =================-->
    <!--================= footer =================-->
    <div class="Footer">
      <footerUser />
    </div>
    <!--================= footer =================-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import footerUser from "@/components/footerUser.vue";
import axios from "axios";
import ApiUrl from "../ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  components: {
    NavBar,
    footerUser,
  },
  data() {
    return {
      dropdown: false,
      products: [],
      categories: [],
      filterCategory: [],
      isLoggedIn: false,
      showLoginSingup: false,
      phoneNumber: "",
      password: "",
      query: "",
      minPrice: 0,
      maxPrice: 0,
      loadingProducts: true,
      NoResults: false,
    };
  },
  async mounted() {
    this.isLoggedIn = this.$store.getters.isLoggedIn;
    let page = document.getElementsByClassName("shop")[0];
    let products = document.getElementsByClassName("products")[0];
    let msg_box = document.getElementById("MessageBox");
    let inputs = document.getElementsByClassName("text-input");
    let search_input = document.getElementsByClassName("search_input")[0];
    let search_btn = document.getElementsByClassName("search_btn")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      products.classList.add("productsL");
      msg_box.classList.add("Message1R");
      search_input.classList.add("search_input_l");
      search_btn.classList.add("search_btn_r");
    } else {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].classList.add("text-input-direction");
      }
      msg_box.classList.add("Message1L");
    }

    await axios
      .get(ApiUrl.url + "/api/Products/0", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.data.length == 0) {
          this.NoResults = true;
          this.loadingProducts = false;
        }
        this.products = res.data;
        this.loadingProducts = false;
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(ApiUrl.url + "/api/Categories/")
      .then((res) => {
        this.categories = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    openDropdown() {
      if (this.dropdown == false) {
        $(".items").fadeIn(200, function () {
          $(".dropdown_title").addClass("dropdown_title_remove_bottom_radius");
        });
        this.dropdown = true;
      } else {
        $(".items").fadeOut(200, function () {
          $(".dropdown_title").removeClass(
            "dropdown_title_remove_bottom_radius"
          );
        });
        this.dropdown = false;
      }
    },
    async addToCart(productid) {
      if (this.isLoggedIn) {
        await axios
          .post(
            ApiUrl.url + "/api/Cart/AddToCart",
            { productID: productid },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              $("#msg").text(this.$i18n.t("ShopPage.addedToCart"));
              $(".Message1").fadeIn(200);
              setTimeout(() => {
                $(".Message1").fadeOut(400);
              }, 3000);
            }
          });
      } else {
        this.showLogiSingPopup();
      }
    },
    showLogiSingPopup() {
      this.showLoginSingup = true;
      setTimeout(() => {
        $("#LoginSingupForms").fadeIn(200);
      }, 0);
    },
    closeLoginSignPopup() {
      $("#LoginSingupForms").fadeOut(200);
      setTimeout(() => {
        this.showLoginSingup = false;
      }, 1000);
    },
    selectCategory(category) {
      this.filterCategory.push(category);
    },
    removeCategoryFromFilterCategory(category) {
      for (let i = 0; i < this.filterCategory.length; i++) {
        if (this.filterCategory[i].id == category.id) {
          this.filterCategory.splice(i, 1);
        }
      }
    },
    closeMsg() {
      $(".Message1").fadeOut(400);
    },
    Login() {
      $("#phonenumber_input").click(function () {
        window.onkeyup = function () {
          $("#phonenumber_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#phonenumber_err").text("");
        };
      });

      $("#password_input").click(function () {
        window.onkeyup = function () {
          $("#password_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#password_err").text("");
        };
      });

      if (this.phoneNumber == "" && this.password == "") {
        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("LoginPage.Phone_empty"));

        $("#password_input").css("border", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("LoginPage.Password_empty"));
        return;
      }
      if (this.phoneNumber == "") {
        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("LoginPage.Phone_empty"));
        return;
      }
      if (this.password == "") {
        $("#password_input").css("borderBottom", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("LoginPage.Password_empty"));
        return;
      }
      this.$store
        .dispatch("Login", {
          phoneNumber: this.phoneNumber,
          password: this.password,
        })
        .then((res) => {
          if (res.data.token) {
            location.reload();
          }
        })
        .catch((err) => {
          if (err.response.data == "Invalid email/phone number or password.") {
            $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
            $("#phonenumber_err").text(this.$i18n.t("LoginPage.LoginErr"));
          }
        });
    },
    async search() {
      this.loadingProducts = true;
      let str = "";
      for (let i = 0; i < this.filterCategory.length; i++) {
        if (this.filterCategory.length - 1 == i) {
          str += this.filterCategory[i].id;
        } else {
          str += this.filterCategory[i].id + ",";
        }
      }
      if (this.filterCategory.length == 0) {
        str = "";
      }
      await axios
        .get(
          ApiUrl.url +
            `/api/Products/filter?query=${this.query}&categories=${str}&minPrice=${this.minPrice}&maxPrice=${this.maxPrice}&isCount=false`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          this.products = res.data;
          this.loadingProducts = false;
          if (this.products.length == 0) {
            this.NoResults = true;
          } else {
            this.NoResults = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex-p {
  flex: 1;
}
.nav {
  height: 80px;
}
/*********************** Lang class ***********************/
/*********************** Lang class ***********************/
/*********************** Page ***********************/
.content {
  display: flex;
  width: 100%;
}
/*********************** Page ***********************/
/*********************** Sidebar ***********************/
.sidebar {
  width: 310px;
}
.sidebar_item1 {
  height: auto;
}
.sidebar_item1_title {
  padding: 10px;
  margin: 1rem;
}
.dropdown {
  text-align: center;
  width: 200px;
  margin: 2rem;
  margin-top: 0.5rem;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transition: all 150ms ease-in-out;
}
.dropdown_title {
  padding: 10px;
  border-radius: 20px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #fff;
    background: #472bff;
  }
}
.dropdown_title_remove_bottom_radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.items {
  background: #fff;
  display: none;
}
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: #472bff;
  }
}
.items,
.item:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.price_range {
  display: flex;
  justify-content: space-around;
}
.from {
  display: flex;
  align-items: center;
}
.to {
  display: flex;
  align-items: center;
}
.price_range_input {
  width: 55px;
  height: 35px;
  padding: 10px;
  border: 1px solid rgb(214, 214, 214);
  transition: all 300ms ease-in-out;
  margin: 1rem;
  border-radius: 10px;
}
.price_range_input:focus {
  border: 1px solid #472bff;
}
/*********************** Sidebar ***********************/
.head {
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.search {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.search_input {
  width: 365px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #eee;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.search_input::placeholder {
  font-weight: bold;
}
.search_input_l {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.search_btn {
  width: 40px;
  height: 45px;
  background-color: #472bff;
  color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3d26d8;
  }
}
.search_btn_r {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.selected_categories {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.selected_category {
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.remove_selected_category {
  font-size: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
/*********************** Products ***********************/
.content-c {
  width: 100%;
}
.box {
  display: flex;
  justify-content: center;
}
.products {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 30px;
  margin-top: 0.5rem;
  margin-right: 3rem;
}
.pro-wrap {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 30px;
  margin-top: 0.5rem;
  margin-right: 3rem;
}
.productsL {
  margin-right: 0;
  margin-left: 3rem;
}
.pro {
  transition: all 300ms ease-in-out;
}
.product {
  width: 350px;
  border-radius: 60px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff; // #fafafa
  transition: all 300ms ease-in-out;
}
.product_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 280px;
  img {
    width: 350px;
    height: 300px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
  }
}
.discount_rate {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  background: #472bff;
  display: inline-block;
  border-radius: 10px;
  padding: 5px;
  color: #fff;
}
.product_info {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 85px;
  color: #1f2833;
}
.product_name {
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  overflow-wrap: break-word;
}
.price {
  width: 120px;
  font-size: 20px;
  font-weight: bold;
}
.add_to_shopping_cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 100px;
  color: #fff;
  background-color: #472bff;
  margin: 0.5rem;
  transition: all 300ms ease-in-out;
  opacity: 0;
  transform: translateY(-4px);
  &:hover {
    cursor: pointer;
    color: #472bff;
    background-color: #fff;
  }
}
.pro:hover {
  .add_to_shopping_cart {
    opacity: 1;
  }
  cursor: pointer;
}

@media screen and (max-width: 1650px) {
  .products {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1430px) {
  .products {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1055px) {
  .products {
    grid-template-columns: auto;
  }
}
/*********************** Products ***********************/
/*********************** Message Box ***********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  border-left: 10px solid #472bff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.Message1R {
  right: 2%;
}
.Message1L {
  left: 2%;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #472bff;
  }
}
/*********************** Message Box ***********************/
/**********************************************/
/*********************** Login/Sign Form ***********************/
#LoginSingupForms {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupContainer {
  width: 600px;
  height: 550px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #1f2833;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  height: calc(70% - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  padding: 1rem;
}
.wel_msg {
  text-align: center;
}
.input_title {
  color: rgb(4, 4, 4);
  font-weight: 600;
  font-size: 18px;
}
.text-input {
  width: 370px;
  height: 40px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.1);
  color: rgb(4, 4, 4);
  transition: all 300ms ease-in-out;
}
.text-input:focus {
  border: 1px solid #472bff;
}
.text-input-direction {
  padding-left: 0;
  padding-right: 10px;
}
.text-input::placeholder {
  color: rgb(214, 214, 214);
}
.input_err {
  color: rgb(250, 82, 82);
  font-weight: 600;
  font-size: 16px;
}
.btn {
  width: 370px;
  height: 40px;
  background-color: #472bff;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3d26d8;
  }
}
.Footer {
  margin-top: 3rem;
}

.loader {
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #472bff 94%, #0000);
  background: var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%,
    var(--_g) 0 100%;
  background-size: 40% 40%;
  animation: l38 0.5s infinite;
}
@keyframes l38 {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
.noResults {
  font-weight: bold;
}
</style>
