import { render, staticRenderFns } from "./ManageProducts.vue?vue&type=template&id=166a37f6&scoped=true&"
import script from "./ManageProducts.vue?vue&type=script&lang=js&"
export * from "./ManageProducts.vue?vue&type=script&lang=js&"
import style0 from "./ManageProducts.vue?vue&type=style&index=0&id=166a37f6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166a37f6",
  null
  
)

export default component.exports