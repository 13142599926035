<template>
  <div class="Login">
    <div class="contanier">
      <div class="left"></div>
      <div class="form">
        <div class="part_one">
          <div class="box_title">
            <h2 class="title">{{ $t("Title") }}</h2>
            <p>
              {{ $t("LoginPage.description") }}
            </p>
          </div>
        </div>
        <div class="part_two" v-if="!waiting">
          <div class="top">
            <router-link :to="{ name: 'Signup' }" class="signup_link">{{
              $t("LoginPage.Link1")
            }}</router-link>
          </div>
          <div class="mid">
            <div>
              <div class="input">
                <h1 class="wel_msg">{{ $t("LoginPage.Welcome") }}</h1>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("LoginPage.phoneNumber")
                }}</label>
                <br />
                <input
                  type="text"
                  class="text-input"
                  id="phonenumber_input"
                  v-model="phoneNumber"
                  :placeholder="$t('LoginPage.phoneNumber')"
                />
                <br />
                <label class="input_err" id="phonenumber_err"></label>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("LoginPage.Password")
                }}</label>
                <br />
                <input
                  type="password"
                  class="text-input"
                  id="password_input"
                  v-model="password"
                  :placeholder="$t('LoginPage.Password')"
                />
                <br />
                <label class="input_err" id="password_err"></label>
              </div>
              <div class="input">
                <div class="links">
                  <router-link to="" class="forget_link">{{
                    $t("LoginPage.Link2")
                  }}</router-link>
                </div>
              </div>
              <div class="input">
                <button type="submit" class="btn" v-on:click="Login()">
                  {{ $t("LoginPage.Login") }}
                </button>
              </div>
            </div>
          </div>
          <div class="bottom"></div>
        </div>
        <div class="waiting" v-if="waiting">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      phoneNumber: "",
      password: "",
      lang: "ar",
      waiting: false,
    };
  },
  async mounted() {
    let Page = document.getElementsByClassName("Login")[0];
    let form = document.getElementsByClassName("form")[0];
    let signup = document.getElementsByClassName("top")[0];
    let inputs = document.getElementsByClassName("text-input");
    let forget_link = document.getElementsByClassName("links")[0];
    if (this.$i18n.locale == "en") {
      Page.style.direction = "ltr";

      this.lang = "en";
    } else {
      form.classList.add("formR");
      signup.classList.add("topR");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].classList.add("text-input-direction");
      }
      forget_link.classList.add("linksR");
    }
  },
  methods: {
    Login() {
      $("#phonenumber_input").click(function () {
        window.onkeyup = function () {
          $("#phonenumber_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#phonenumber_err").text("");
        };
      });

      $("#password_input").click(function () {
        window.onkeyup = function () {
          $("#password_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#password_err").text("");
        };
      });

      if (this.phoneNumber == "" && this.password == "") {
        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("LoginPage.Phone_empty"));

        $("#password_input").css("border", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("LoginPage.Password_empty"));
        return;
      }
      if (this.phoneNumber == "") {
        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("LoginPage.Phone_empty"));
        return;
      }
      if (this.password == "") {
        $("#password_input").css("borderBottom", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("LoginPage.Password_empty"));
        return;
      }
      this.waiting = true;
      this.$store
        .dispatch("Login", {
          phoneNumber: this.phoneNumber,
          password: this.password,
        })
        .then((res) => {
          if (res.data.token) {
            this.waiting = false;
            this.$router.push({ name: "Shop" });
            location.reload();
          }
        })
        .catch((err) => {
          if (err.response.data == "Invalid email/phone number or password.") {
            $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
            $("#phonenumber_err").text(this.$i18n.t("LoginPage.LoginErr"));
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/*********************** Page ***********************/
.Login {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
/*********************** Page ***********************/
.contanier {
  display: flex;
  align-items: center;
}
.left {
  height: 100vh;
  width: calc(100% - 70%);
  background-color: #472bff;
}
.form {
  position: relative;
  left: -15%;
  width: 70%;
  height: 75vh;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
}
.formR {
  left: 0;
  right: -15%;
}
.part_one {
  width: 21.45%;
  height: 100%;
  background-color: #472bff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_title {
  text-align: center;
  margin: 1rem;
  color: #fff;
  p {
    text-align: center;
  }
}
.part_two {
  width: 100%;
}
.top {
  text-align: right;
  padding: 1rem;
  height: 10vh;
}
.topR {
  text-align: left;
}
.signup_link {
  color: rgb(146, 146, 146);
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}
.mid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.input {
  padding: 1rem;
}
.wel_msg {
  text-align: center;
}
.input_title {
  color: rgb(4, 4, 4);
  font-weight: 600;
  font-size: 18px;
}
.text-input {
  width: 370px;
  height: 40px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 10px;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.1);
  color: rgb(4, 4, 4);
  transition: all 300ms ease-in-out;
}
.text-input:focus {
  border: 1px solid #472bff;
}
.text-input-direction {
  padding-left: 0;
  padding-right: 10px;
}
.text-input::placeholder {
  color: rgb(214, 214, 214);
}
.input_err {
  color: rgb(250, 82, 82);
  font-weight: 600;
  font-size: 16px;
}
.links {
  text-align: right;
  .forget_link {
    color: rgb(146, 146, 146);
    transition: all 300ms ease-in-out;
    &:hover {
      color: #472bff;
    }
  }
}
.linksR {
  text-align: left;
}
.btn {
  width: 370px;
  height: 40px;
  background-color: #472bff;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3d26d8;
  }
}
.waiting {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #472bff 94%, #0000);
  background: var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%,
    var(--_g) 0 100%;
  background-size: 40% 40%;
  animation: l38 0.5s infinite;
}
@keyframes l38 {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
</style>
