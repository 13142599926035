<template>
  <div class="admin">
    <div class="a">
      <!--================= Header =================-->
      <div class="nav">
        <AdminNavbarVue />
      </div>
      <!--================= Header =================-->
      <!--================= Welcome =================-->

      <div class="Welcome_msg">
        <h2>{{ $t("AdminPage.WelcomeMsg") }}</h2>
      </div>
      <!--================= Welcome =================-->
      <!--================= Router View =================-->
      <div class="view">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
      <!--================= Router View =================-->
    </div>

    <!--================= footer =================-->
    <div class="Footer">
      <footerUser />
    </div>
    <!--================= footer =================-->
  </div>
</template>

<script>
import AdminNavbarVue from "@/components/AdminNavbar.vue";
import footerUser from "@/components/footerUser.vue";
export default {
  data() {
    return {};
  },
  components: {
    AdminNavbarVue,
    footerUser,
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("admin")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
    }
  },
};
</script>

<style lang="scss" scoped>
.admin {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.a {
  flex: 1;
}
.nav {
  height: 80px;
}
.Welcome_msg {
  margin: 1.5rem;
  margin-bottom: 0rem;
}
.view {
  margin: 1rem;
}
</style>
