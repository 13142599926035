<template>
  <div class="Login">
    <div class="contanier">
      <div class="left"></div>
      <div class="form">
        <div class="part_one">
          <div class="box_title">
            <h2 class="title">{{ $t("Title") }}</h2>
            <p>
              {{ $t("SignupPage.description") }}
            </p>
          </div>
        </div>
        <div class="part_two">
          <div class="top">
            <router-link :to="{ name: 'Login' }" class="login_link">{{
              $t("SignupPage.Link1")
            }}</router-link>
          </div>
          <div class="mid">
            <div>
              <div class="input">
                <h1 class="wel_msg">{{ $t("SignupPage.Welcome") }}</h1>
              </div>

              <!--================= Name Input =================-->
              <div class="input">
                <label class="input_title">{{ $t("SignupPage.name") }}</label>
                <br />
                <input
                  type="text"
                  class="text-input"
                  id="name_input"
                  autocomplete="off"
                  v-model="name"
                  :placeholder="$t('SignupPage.name')"
                />
                <br />
                <label class="input_err" id="name_err"></label>
              </div>
              <!--================= Name Input =================-->

              <!--================= Email Input =================-->
              <div class="input">
                <label class="input_title">{{ $t("SignupPage.email") }}</label>
                <br />
                <input
                  type="text"
                  class="text-input"
                  id="email_input"
                  autocomplete="off"
                  v-model="email"
                  :placeholder="$t('SignupPage.email')"
                />
                <br />
                <label class="input_err" id="email_err"></label>
              </div>
              <!--================= Email Input =================-->

              <!--================= phone number Input =================-->
              <div class="input">
                <label class="input_title">{{
                  $t("SignupPage.phoneNumber")
                }}</label>
                <br />
                <input
                  type="text"
                  class="text-input"
                  id="phonenumber_input"
                  v-model="phoneNumber"
                  :placeholder="$t('SignupPage.phoneNumber')"
                />
                <br />
                <label class="input_err" id="phonenumber_err"></label>
              </div>
              <!--================= phone number Input =================-->

              <!--================= password Input =================-->
              <div class="input">
                <label class="input_title">{{
                  $t("SignupPage.Password")
                }}</label>
                <br />
                <input
                  type="password"
                  class="text-input"
                  id="password_input"
                  v-model="password"
                  :placeholder="$t('SignupPage.Password')"
                />
                <br />
                <label class="input_err" id="password_err"></label>
              </div>
              <!--================= password Input =================-->

              <div class="input">
                <button type="submit" class="btn" v-on:click="Signup()">
                  {{ $t("SignupPage.Signup") }}
                </button>
              </div>
            </div>
          </div>
          <div class="bottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      lang: "ar",
    };
  },
  async mounted() {
    let Page = document.getElementsByClassName("Login")[0];
    let form = document.getElementsByClassName("form")[0];
    let signup = document.getElementsByClassName("top")[0];
    let inputs = document.getElementsByClassName("text-input");
    if (this.$i18n.locale == "en") {
      Page.style.direction = "ltr";

      this.lang = "en";
    } else {
      form.classList.add("formR");
      signup.classList.add("topR");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].classList.add("text-input-direction");
      }
    }
  },
  methods: {
    Signup() {
      $("#name_input").click(function () {
        window.onkeyup = function () {
          $("#name_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#name_err").text("");
        };
      });

      $("#email_input").click(function () {
        window.onkeyup = function () {
          $("#email_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#email_err").text("");
        };
      });

      $("#phonenumber_input").click(function () {
        window.onkeyup = function () {
          $("#phonenumber_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#phonenumber_err").text("");
        };
      });

      $("#password_input").click(function () {
        window.onkeyup = function () {
          $("#password_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#password_err").text("");
        };
      });

      if (
        this.name == "" &&
        this.email == "" &&
        this.phoneNumber == "" &&
        this.password == ""
      ) {
        $("#name_input").css("border", "1px solid rgb(250,82,82)");
        $("#name_err").text(this.$i18n.t("SignupPage.Name_empty"));

        $("#email_input").css("border", "1px solid rgb(250,82,82)");
        $("#email_err").text(this.$i18n.t("SignupPage.Email_empty"));

        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("SignupPage.Phone_empty"));

        $("#password_input").css("border", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("SignupPage.Password_empty"));
        return;
      }
      if (this.name == "") {
        $("#name_input").css("border", "1px solid rgb(250,82,82)");
        $("#name_err").text(this.$i18n.t("SignupPage.Name_empty"));
        return;
      }

      if (this.phoneNumber == "") {
        $("#phonenumber_input").css("border", "1px solid rgb(250,82,82)");
        $("#phonenumber_err").text(this.$i18n.t("SignupPage.Phone_empty"));
        return;
      }

      if (this.password == "") {
        $("#password_input").css("border", "1px solid rgb(250,82,82)");
        $("#password_err").text(this.$i18n.t("SignupPage.Password_empty"));
        return;
      }
      this.$store
        .dispatch("SignUp", {
          name: this.name,
          email: this.email,
          password: this.password,
          phone: this.phoneNumber,
        })
        .then((res) => {
          if (res.data.token) {
            this.$router.push({ name: "Shop" });
          }
        })
        .catch((err) => {
          if (err.response.data == "Email already used!") {
            $("#email_input").css("borderBottom", "3px solid rgb(250,82,82)");
            $(".email_input_label").text(
              this.$i18n.t("SignupPage.EmailAlreadyFound")
            );
          }

          if (err.response.data == "Phone number already used!") {
            $("#phonenumber_input").css(
              "borderBottom",
              "3px solid rgb(250,82,82)"
            );
            $("#phonenumber_err").text(
              this.$i18n.t("SignupPage.PhoneNumberAlreadyFound")
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/*********************** Page ***********************/
.Login {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
/*********************** Page ***********************/
.contanier {
  display: flex;
  align-items: center;
}
.left {
  height: 100vh;
  width: calc(100% - 70%);
  background-color: #472bff;
}
.form {
  position: relative;
  left: -15%;
  width: 70%;
  height: 75vh;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
}
.formR {
  left: 0;
  right: -15%;
}
.part_one {
  width: 21.45%;
  height: 100%;
  background-color: #472bff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_title {
  text-align: center;
  margin: 1rem;
  color: #fff;
  p {
    width: 250px;
    text-align: center;
  }
}
.part_two {
  width: 100%;
}
.top {
  text-align: right;
  padding: 1rem;
  height: 10vh;
}
.topR {
  text-align: left;
}
.login_link {
  color: rgb(146, 146, 146);
  transition: all 300ms ease-in-out;
  &:hover {
    color: #472bff;
  }
}
.mid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
}
.input {
  padding: 1rem;
}
.wel_msg {
  text-align: center;
}
.input_title {
  color: rgb(4, 4, 4);
  font-weight: 600;
  font-size: 18px;
}
.text-input {
  width: 370px;
  height: 40px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 10px;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.1);
  color: rgb(4, 4, 4);
  transition: all 300ms ease-in-out;
}
.text-input:focus {
  border: 1px solid #472bff;
}
.text-input-direction {
  padding-left: 0;
  padding-right: 10px;
}
.text-input::placeholder {
  color: rgb(214, 214, 214);
}
.input_err {
  color: rgb(250, 82, 82);
  font-weight: 600;
  font-size: 16px;
}
.links {
  text-align: right;
  .forget_link {
    color: rgb(146, 146, 146);
    transition: all 300ms ease-in-out;
    &:hover {
      color: #472bff;
    }
  }
}
.linksR {
  text-align: left;
}
.btn {
  width: 370px;
  height: 40px;
  background-color: #472bff;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3d26d8;
  }
}
</style>
