import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import ApiUrl from "@/ApiUrl";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    isAdmin: localStorage.getItem("#5s12") || null,
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    isAdminLoggedIn(state) {
      return state.isAdmin !== null;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
    },
    admin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
  },
  actions: {
    verifyToken() {
      if (this.getters.isLoggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              ApiUrl.url +
                `/api/Authentication/ValidateToken?authToken=${localStorage.getItem(
                  "token"
                )}`
            )
            .then((res) => {
              if (res.data == false) {
                localStorage.clear();
                location.replace("/");
                resolve(res);
              } else if (res.data == true) {
                resolve(res);
              } else {
                localStorage.clear();
                location.replace("/");
                resolve(res);
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
    Login(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(ApiUrl.url + "/api/Authentication/Login", {
            emailOrPhoneNumber: data.phoneNumber,
            password: data.password,
          })
          .then((res) => {
            const token = res.data.token;
            if (res.data.role == "Admin") {
              localStorage.setItem("#5s12", true);
              context.commit("admin", true);
            }
            if (res.data.token) {
              localStorage.setItem("token", token);
              context.commit("login", token);
              resolve(res);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SignUp(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(ApiUrl.url + "/api/Authentication/SignUp", {
            name: data.name,
            password: data.password,
            phone: data.phone,
            email: data.email,
          })
          .then((res) => {
            const token = res.data.token;
            if (res.data.token) {
              localStorage.setItem("token", token);
              context.commit("login", token);
              resolve(res);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
