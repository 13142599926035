<template>
  <div class="manageOrders">
    <h2 class="section_title">{{ $t("AdminPage.ManageOrders.Title") }}</h2>
    <!--============== Statistics ==============-->
    <div class="stat">
      <div class="stats">
        <div class="stat_box">
          <div class="stat_box_icon"><i class="fa-solid fa-cube"></i></div>
          <div class="stat_box_info">
            <div class="stat_title">
              {{ $t("AdminPage.ManageOrders.box0Title") }}
            </div>
            <div class="stat_number">{{ pendingOrderNumber }}</div>
          </div>
        </div>

        <div class="stat_box">
          <div class="stat_box_icon"><i class="fa-solid fa-dolly"></i></div>
          <div class="stat_box_info">
            <div class="stat_title">
              {{ $t("AdminPage.ManageOrders.box1Title") }}
            </div>
            <div class="stat_number">{{ OnthwayOrderNumber }}</div>
          </div>
        </div>

        <div class="stat_box">
          <div class="stat_box_icon"><i class="fa-solid fa-box"></i></div>
          <div class="stat_box_info">
            <div class="stat_title">
              {{ $t("AdminPage.ManageOrders.box2Title") }}
            </div>
            <div class="stat_number">{{ DoneOrderNumber }}</div>
          </div>
        </div>

        <div class="stat_box">
          <div class="stat_box_icon">
            <i class="fa-solid fa-square-xmark"></i>
          </div>
          <div class="stat_box_info">
            <div class="stat_title">
              {{ $t("AdminPage.ManageOrders.box4Title") }}
            </div>
            <div class="stat_number">{{ FailedOrderNumber }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--============== Statistics ==============-->
    <!--============== Management Buttons ==============-->
    <div class="manage">
      <div class="managementBtns">
        <div class="sortByStatus">
          <div class="dropdown">
            <button class="dropbtn" v-on:click="showStatusDropdown()">
              {{ $t("AdminPage.ManageOrders.sortByStatus") }} {{ selectSort }}
            </button>
            <div class="dropdown-content">
              <div
                v-for="state in status"
                v-bind:key="state.id"
                v-on:click="sortByStatus(state.name)"
              >
                <span
                  v-if="state.name == 'Pending'"
                  v-on:click="sortByStatus(state.name)"
                  >{{ $t("AdminPage.OrderView.status1") }}</span
                >
                <span
                  v-if="state.name == 'On the way'"
                  v-on:click="sortByStatus(state.name)"
                  >{{ $t("AdminPage.OrderView.status2") }}</span
                >
                <span
                  v-if="state.name == 'Done'"
                  v-on:click="sortByStatus(state.name)"
                  >{{ $t("AdminPage.OrderView.status3") }}</span
                >
                <span
                  v-if="state.name == 'Failed'"
                  v-on:click="sortByStatus(state.name)"
                  >{{ $t("AdminPage.OrderView.status4") }}</span
                >
              </div>
              <div v-on:click="loadOrders()">
                {{ $t("AdminPage.ManageOrders.showAll") }}
              </div>
            </div>
          </div>
        </div>
        <div class="sortByDate">
          <button class="dropbtn1" v-on:click="showDateInputs()">
            {{ $t("AdminPage.ManageOrders.sortByDate") }}
          </button>
        </div>
        <div class="hideSearch">
          <button class="dropbtn2" v-on:click="hideSearch()">
            اغلاق البحث
          </button>
        </div>
        <div class="search_input">
          <div>
            <input
              type="text"
              :placeholder="$t('AdminPage.ManageOrders.searchInput')"
              class="search_input_text"
              @keyup.enter="searchForOrder()"
              v-model="orderNo"
            />
            <button class="search_btn" v-on:click="searchForOrder()">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="dateForm">
      <div class="inputs" v-if="dateInputs">
        <div class="input">
          <span class="input_title"> من</span>
          <input type="date" class="date_input" v-model="fromDate" />
        </div>
        <div class="input">
          <span class="input_title"> الى </span>
          <input type="date" class="date_input" v-model="toDate" />
        </div>
        <div class="input">
          <button v-on:click="sortByDate()" class="sortByDateBtn">بحث</button>
        </div>
      </div>
    </div>
    <!--============== Management Buttons ==============-->
    <!--============== Orders Table ==============-->
    <div class="orders_table">
      <div class="o_table">
        <div class="first_row">
          <div class="n">#</div>
          <div class="customerName">
            {{ $t("AdminPage.ManageOrders.OrdersTable.customerName") }}
          </div>
          <div class="phoneNumber">
            {{ $t("AdminPage.ManageOrders.OrdersTable.phoneNumber") }}
          </div>
          <div class="date">
            {{ $t("AdminPage.ManageOrders.OrdersTable.Date") }}
          </div>
          <div class="status">
            {{ $t("AdminPage.ManageOrders.OrdersTable.status") }}
          </div>
          <div class="totalprice">
            {{ $t("AdminPage.ManageOrders.OrdersTable.price") }}
          </div>
          <div class="details">
            {{ $t("AdminPage.ManageOrders.OrdersTable.details") }}
          </div>
        </div>
        <div class="row" v-for="order in Orders" v-bind:key="order.orderNo">
          <div class="n">#</div>
          <div class="customerName">{{ order.user.name }}</div>
          <div class="phoneNumber">{{ order.user.phone }}</div>
          <div class="date">{{ order.date.split("T")[0] }}</div>
          <div class="status" v-if="order.status.name == 'Pending'">
            {{ $t("AdminPage.ManageOrders.status1") }}
          </div>
          <div class="status" v-if="order.status.name == 'On the way'">
            {{ $t("AdminPage.ManageOrders.status2") }}
          </div>
          <div class="status" v-if="order.status.name == 'Done'">
            {{ $t("AdminPage.ManageOrders.status3") }}
          </div>
          <div class="status" v-if="order.status.name == 'Failed'">
            {{ $t("AdminPage.ManageOrders.status4") }}
          </div>
          <div class="totalprice">{{ order.total }} ₪</div>
          <div class="details">
            <button class="view_btn" v-on:click="viewOrder(order.orderNo)">
              <i class="fa-regular fa-eye"></i>
            </button>
          </div>
        </div>
        <div class="row_empty" v-if="Orders.length == 0">
          <div class="message">{{ $t("AdminPage.ManageOrders.noOrders") }}</div>
        </div>
      </div>
    </div>
    <!--============== Orders Table ==============-->
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "../../ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      Orders: [],
      fromDate: "",
      toDate: "",
      dateInputs: false,
      orderNo: "",
      status: [],
      selectSort: "",
      pendingOrderNumber: 0,
      OnthwayOrderNumber: 0,
      DoneOrderNumber: 0,
      FailedOrderNumber: 0,
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("manageOrders")[0];
    let section_title = document.getElementsByClassName("section_title");
    let search_input_text =
      document.getElementsByClassName("search_input_text")[0];
    let search_btn = document.getElementsByClassName("search_btn")[0];
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
      search_input_text.classList.add("search_input_text_en");
      search_btn.classList.add("search_btn_en");
    } else {
      search_input_text.classList.remove("search_input_text_en");
      search_btn.classList.remove("search_btn_en");
    }
    this.loadStatus();
    this.loadOrders();

    // Pending Orders
    await axios
      .get(
        ApiUrl.url +
          `/api/Orders/filter?orderNo=&userId=0&fromDate=&toDate=&status=Pending&isCount=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.pendingOrderNumber = res.data;
      });

    // On the way Orders
    await axios
      .get(
        ApiUrl.url +
          `/api/Orders/filter?orderNo=&userId=0&fromDate=&toDate=&status=On the way&isCount=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.OnthwayOrderNumber = res.data;
      });

    // Done Orders
    await axios
      .get(
        ApiUrl.url +
          `/api/Orders/filter?orderNo=&userId=0&fromDate=&toDate=&status=Done&isCount=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.DoneOrderNumber = res.data;
      });

    // Failed Orders
    await axios
      .get(
        ApiUrl.url +
          `/api/Orders/filter?orderNo=&userId=0&fromDate=&toDate=&status=Failed&isCount=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.FailedOrderNumber = res.data;
      });
  },
  methods: {
    async loadStatus() {
      await axios
        .get(ApiUrl.url + "/api/Status", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.status = res.data;
        });
    },
    async loadOrders() {
      this.selectSort = "";
      await axios
        .get(ApiUrl.url + "/api/Orders/GetAll", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.Orders = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showStatusDropdown() {
      $(".dropdown-content").show();
    },
    viewOrder(orderNo) {
      this.$router.push(`/${this.$i18n.locale}/Admin/Order/${orderNo}`);
    },
    async sortByStatus(status) {
      this.selectSort = status;
      await axios
        .get(
          ApiUrl.url +
            `/api/Orders/filter?orderNo=&userId=0&fromDate=&toDate=&status=${status}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Orders = res.data;
        });
    },
    async showDateInputs() {
      if (this.dateInputs == true) {
        this.dateInputs = false;
      } else {
        this.dateInputs = true;
      }
    },
    hideSearch() {
      this.dateInputs = false;
    },
    async sortByDate() {
      await axios
        .get(
          ApiUrl.url +
            `/api/Orders/filter?orderNo=&userId=0&fromDate=${this.fromDate}&toDate=${this.toDate}&status=`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Orders = res.data;
        });
    },
    async searchForOrder() {
      await axios
        .get(
          ApiUrl.url +
            `/api/Orders/filter?orderNo=${this.orderNo}&userId=0&fromDate=&toDate=&status=`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Orders = res.data;
        });
    },
  },
};
window.onclick = function (e) {
  if (!(e.target.classList[0] == "dropbtn")) {
    $(".dropdown-content").hide();
  }
};
</script>

<style lang="scss" scoped>
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}
/************* Statistics *************/
.stat {
  display: flex;
  justify-content: center;
}
.stats {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.stat_box {
  width: 300px;
  height: 110px;
  background-color: #1f2833;
  border-bottom: 5px solid #472bff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  display: flex;
}
.stat_box_icon {
  width: 70px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 32px;
    color: #472bff;
  }
}
.stat_box_info {
  width: 230px;
  height: 110px;
  text-align: center;
}
.stat_title {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #eeeeee;
}
.stat_number {
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: rgb(170, 170, 170);
}
@media screen and (max-width: 990px) {
  .stats {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 640px) {
  .stats {
    grid-template-columns: auto;
  }
}
/************* Statistics *************/
.orders_table {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.o_table {
  width: 70%;
  background-color: #1f2833;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.first_row {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    color: #eeeeee;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  .n {
    width: 10%;
  }
  .customerName {
    width: 20%;
  }
  .phoneNumber {
    width: 15%;
  }
  .date {
    width: 15%;
  }
  .status {
    width: 10%;
  }
  .totalprice {
    width: 10%;
  }
  .details {
    width: 10%;
  }
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    color: rgb(170, 170, 170);
    font-size: 17px;
    display: flex;
    justify-content: center;
  }
  .n {
    width: 10%;
  }
  .customerName {
    width: 20%;
  }
  .phoneNumber {
    width: 15%;
  }
  .date {
    width: 15%;
  }
  .status {
    width: 10%;
  }
  .totalprice {
    width: 10%;
  }
  .details {
    width: 10%;
    padding: 10px;

    .view_btn {
      background-color: #472bff;
      width: 40px;
      height: 40px;
      color: #eeeeee;
      border-radius: 10px;
      transition: all 300ms ease-in-out;
      &:hover {
        cursor: pointer;
        background-color: #3622bd;
      }
    }
  }
}
.row_empty {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message {
  display: flex;
  justify-content: center;
  color: rgb(170, 170, 170);
  font-weight: 600;
}
.manage {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.managementBtns {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  .sortByStatus,
  .sortByDate,
  .hideSearch,
  .search_input {
    margin: 1rem;
  }
}
.dropbtn,
.dropbtn1,
.dropbtn2 {
  width: 140px;
  height: 50px;
  background: #472bff;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3622bd;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: all 300ms ease-in-out;
}
.dropdown-content div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.dateForm {
  display: flex;
  justify-content: center;
}
.inputs {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}
.input {
  margin: 1rem;
  margin-bottom: 0rem;
}
.input_title {
  margin: 20px;
  font-weight: 600;
}
.date_input {
  width: 200px;
  height: 40px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
}
.sortByDateBtn {
  width: 70px;
  height: 40px;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3622bd;
  }
}
.search_input {
  display: flex;
  align-items: center;
}
.search_input_text {
  width: 230px;
  height: 50px;
  padding: 10px;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.search_input_text_en {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search_btn {
  width: 40px;
  height: 50px;
  background: #472bff;
  color: #eeeeee;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.search_btn_en {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
