<template>
  <div class="updateAddress">
    <div class="u">
      <h2>{{ $t("ProfilePage.UserSettings.UpdateAddressPage.title") }}</h2>
      <div class="manage_address">
        <div class="dropdown">
          <button v-on:click="openDrop()" class="dropbtn">
            {{ $t("ProfilePage.UserSettings.UpdateAddressPage.manageBtn1") }}
          </button>
          <div id="myDropdown" class="dropdown-content" v-if="isOpen">
            <div
              v-for="address in addresses"
              v-bind:key="address.id"
              v-on:click="load(address)"
            >
              {{ address.name }}
            </div>
          </div>
        </div>
        <div class="add">
          <button class="add_btn" v-on:click="Add()">
            {{ $t("ProfilePage.UserSettings.UpdateAddressPage.manageBtn2") }}
          </button>
        </div>
      </div>
      <!--================= Update Form =================-->
      <div class="form" v-if="update">
        <form method="post" @submit.prevent="updateAddress()">
          <div class="input">
            <input
              type="text"
              id="name_input"
              :placeholder="
                $t('ProfilePage.UserSettings.UpdateAddressPage.placeholder1')
              "
              class="text-input"
              v-model="Name"
            />
            <br />
            <label for="name_input" class="name_input_label"></label>
          </div>
          <div class="input">
            <input
              type="text"
              id="Description_input"
              :placeholder="
                $t('ProfilePage.UserSettings.UpdateAddressPage.placeholder2')
              "
              class="text-input"
              v-model="Description"
            />
            <br />
            <label
              for="Description_input"
              class="description_input_label"
            ></label>
          </div>
          <div class="input">
            <button class="update_name_btn">
              {{ $t("ProfilePage.UserSettings.UpdateAddressPage.Update") }}
            </button>
          </div>
          <div class="input center">
            <span class="update_success"></span>
          </div>
        </form>
      </div>
      <!--================= Update Form =================-->
      <!--================= Add Form =================-->
      <div class="form" v-if="add">
        <form method="post" @submit.prevent="addAddress()">
          <div class="input">
            <input
              type="text"
              id="new_name_input"
              :placeholder="
                $t('ProfilePage.UserSettings.UpdateAddressPage.placeholder1')
              "
              class="text-input"
              v-model="nName"
            />
            <br />
            <label for="new_name_input" class="new_name_input_label"></label>
          </div>
          <div class="input">
            <input
              type="text"
              id="new_Description_input"
              :placeholder="
                $t('ProfilePage.UserSettings.UpdateAddressPage.placeholder2')
              "
              class="text-input"
              v-model="nDescription"
            />
            <br />
            <label
              for="new_Description_input"
              class="new_description_input_label"
            ></label>
          </div>
          <div class="input">
            <button class="update_name_btn">
              {{ $t("ProfilePage.UserSettings.UpdateAddressPage.Add") }}
            </button>
          </div>
          <div class="input">
            <span class="add_success"></span>
          </div>
        </form>
      </div>
      <!--================= Add Form =================-->
    </div>
  </div>
</template>

<script>
import jquery from "jquery";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
let $ = jquery;
export default {
  data() {
    return {
      isOpen: false,
      update: false,
      add: false,
      Name: "",
      Description: "",
      nName: "",
      nDescription: "",
      addressId: 0,
      addresses: [],
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("updateAddress")[0];
      page.style.direction = "ltr";
    }
    this.loadAddresses();
  },
  methods: {
    async loadAddresses() {
      await axios
        .get(ApiUrl.url + "/api/Address/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.addresses = res.data;
        });
    },
    Add() {
      this.update = false;
      this.isOpen = false;
      setTimeout(() => {
        this.add = true;
      }, 1);
    },
    load(address) {
      this.add = false;
      this.isOpen = false;
      this.Name = address.name;
      this.Description = address.description;
      this.addressId = address.id;
      setTimeout(() => {
        this.update = true;
      }, 1);
    },
    openDrop() {
      if (this.isOpen == false) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },
    async updateAddress() {
      $("#name_input").click(function () {
        window.onkeypress = function () {
          $("#name_input").css("borderBottom", "3px solid #472bff");
          $(".name_input_label").text("");
        };
      });

      $("#Description_input").click(function () {
        window.onkeypress = function () {
          $("#Description_input").css("borderBottom", "3px solid #472bff");
          $(".description_input_label").text("");
        };
      });

      if (this.Name == "" && this.Description == "") {
        $("#name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err1")
        );

        $("#Description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".description_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err2")
        );
        return;
      }

      if (this.Name == "") {
        $("#name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err1")
        );
        return;
      }

      if (this.Description == "") {
        $("#Description_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".description_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err2")
        );
        return;
      }
      await axios
        .put(
          ApiUrl.url + `/api/Address/${this.addressId}`,
          {
            name: this.Name,
            description: this.Description,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".update_success").text(
              this.$i18n.t(
                "ProfilePage.UserSettings.UpdateAddressPage.success_msg2"
              )
            );
            this.loadAddresses();
          }
        });
    },
    async addAddress() {
      $("#new_name_input").click(function () {
        window.onkeydown = function () {
          $("#new_name_input").css("borderBottom", "3px solid #472bff");
          $(".new_name_input_label").text("");
        };
      });

      $("#new_Description_input").click(function () {
        window.onkeydown = function () {
          $("#new_Description_input").css("borderBottom", "3px solid #472bff");
          $(".new_description_input_label").text("");
        };
      });

      if (this.nName == "" && this.nDescription == "") {
        $("#new_name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".new_name_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err1")
        );

        $("#new_Description_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_description_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err2")
        );
        return;
      }

      if (this.nName == "") {
        $("#new_name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".new_name_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err1")
        );
        return;
      }

      if (this.nDescription == "") {
        $("#new_Description_input").css(
          "borderBottom",
          "3px solid rgb(250,82,82)"
        );
        $(".new_description_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateAddressPage.err2")
        );
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/api/Address",
          {
            name: this.nName,
            description: this.nDescription,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".add_success").text(
              this.$i18n.t(
                "ProfilePage.UserSettings.UpdateAddressPage.success_msg1"
              )
            );
            this.loadAddresses();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.updateAddress {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.u {
  h2 {
    text-align: center;
  }
}
.manage_address {
  display: flex;
  justify-content: center;
  align-items: center;
  .dropdown,
  .add {
    margin: 1rem;
  }
}
.dropbtn {
  background-color: #d5d5d5;
  color: #1f2833;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
}
.dropbtn:hover {
  background-color: #cfcfcf;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content div:hover {
  cursor: pointer;
  background-color: #ddd;
}
.add_btn {
  background-color: #d5d5d5;
  color: #1f2833;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border-radius: 10px;
  &:hover {
    background-color: #cfcfcf;
  }
}
/*********************** Form ***********************/
.form {
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }
}
.input {
  padding: 1rem;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  border-bottom: 3px solid #472bff;
  transition: all 300ms ease-in-out;
}
.update_name_btn {
  width: 365px;
  height: 40px;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  font-size: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.name_input_label,
.description_input_label,
.new_name_input_label,
.new_description_input_label {
  color: rgb(250, 82, 82);
}
.input:last-child {
  text-align: center;
}
.center {
  text-align: center;
}
/*********************** Form ***********************/
</style>
