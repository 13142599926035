<template>
  <div class="manageProducts">
    <h2 class="section_title">{{ $t("AdminPage.ManageProducts.Title") }}</h2>

    <!--================= Manage Button =================-->
    <div class="manage">
      <div class="add_product">
        <button class="add_product_btn" v-on:click="addProduct()">
          {{ $t("AdminPage.ManageProducts.ManageBtn1") }}
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
      <div class="search">
        <input
          type="text"
          class="search_input"
          @keyup.enter="searchProduct()"
          v-model="query"
          :placeholder="$t('AdminPage.ManageProducts.Search')"
        />
        <button class="search_btn" v-on:click="searchProduct()">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <!--================= Manage Button =================-->
    <!--================= Display Products =================-->
    <div class="displayProducts">
      <div class="products">
        <div
          class="product"
          v-for="product in products"
          v-bind:key="product.id"
        >
          <div class="product_image">
            <img :src="product.image" />
          </div>
          <div class="product_info">
            <div class="product_name">
              {{ product.name }}
            </div>
            <div class="managemenet_btns">
              <button class="edit_btn" v-on:click="editProduct(product.id)">
                <i class="fa-regular fa-pen-to-square"></i>
              </button>
              <button class="delete_btn">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "../../ApiUrl";
export default {
  components: {},
  data() {
    return {
      query: "",
      products: [],
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("manageProducts")[0];
    let section_title = document.getElementsByClassName("section_title");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    }
    await axios
      .get(ApiUrl.url + "/api/Products/0", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((res) => {
        this.products = res.data;
      });
  },
  methods: {
    addProduct() {
      this.$router.push({ name: "AddProduct" });
    },
    async searchProduct() {
      await axios
        .get(
          ApiUrl.url +
            `/api/Products/filter?query=${this.query}&categories=&minPrice=0&maxPrice=0&isCount=false`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          this.products = res.data;
        });
    },
    editProduct(productid) {
      this.$router.push(`/${this.$i18n.locale}/Admin/EditProduct/${productid}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}

/*********************** Manage Button ***********************/
.manage {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.add_product_btn {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: #472bff;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3b24d2;
  }
}
.search {
  display: flex;
  align-items: center;
}
.search_input {
  width: 365px;
  height: 40px;
  background-color: #eeeeee;
  padding: 10px;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}
.search_input:focus {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.search_btn {
  width: 40px;
  height: 40px;
  margin: 1rem;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.search_input::placeholder {
  color: #1f2833;
}
/*********************** Manage Button ***********************/
/*********************** Display Products ***********************/
.displayProducts {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.products {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 40px;
}
.product {
  width: 350px;
  height: 400px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product_image {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 300px;
  }
}
.product_info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 100px;
}
.product_name {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 17px;
  font-weight: 600;
}
.managemenet_btns {
  width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 45px;
    height: 45px;
    background-color: #472bff;
    margin: 0.2rem;
    color: #eeeeee;
    border-radius: 100px;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      color: #472bff;
      background-color: #eeeeee;
    }
  }
}
/*********************** Display Products ***********************/
</style>
