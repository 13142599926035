<template>
  <div class="profile">
    <!--================= Header =================-->
    <div class="nav">
      <NavBar />
    </div>
    <!--================= Header =================-->
    <!--================= User info =================-->
    <div class="pro">
      <div class="info">
        <div class="box">
          <div class="title">
            <h2>{{ $t("ProfilePage.PersonalInfo") }}</h2>
          </div>
          <div class="name">{{ $t("ProfilePage.Name") }} : {{ name }}</div>
          <div class="Email">{{ $t("ProfilePage.Email") }} : {{ Email }}</div>
          <div class="PhoneNumber">
            {{ $t("ProfilePage.PNumber") }} : {{ phoneNumber }}
          </div>
        </div>
      </div>
      <div class="links1">
        <h2 class="update_title">{{ $t("ProfilePage.UpdateTitle") }}</h2>
        <ul>
          <li>
            <router-link :to="{ name: 'UpdateName' }" class="update_link">{{
              $t("ProfilePage.Link1")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UpdateEmail' }" class="update_link">{{
              $t("ProfilePage.Link2")
            }}</router-link>
          </li>
          <li>
            <router-link to="" class="update_link">{{
              $t("ProfilePage.Link3")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UpdateAddress' }" class="update_link">{{
              $t("ProfilePage.Link4")
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'UpdatePassword' }" class="update_link">{{
              $t("ProfilePage.Link5")
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--================= User info =================-->
    <!--================= Router-view =================-->
    <div class="d">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <!--================= Router-view =================-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
export default {
  data() {
    return {
      name: "",
      Email: "",
      phoneNumber: "",
    };
  },
  components: {
    NavBar,
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("profile")[0];
      page.style.direction = "ltr";
    }

    await axios
      .get(ApiUrl.url + "/api/User", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.Email = res.data.email;
        this.name = res.data.name;
        this.phoneNumber = res.data.phone;
      });
  },
  methods: {
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    click(data) {
      // this.$router.push(`/${data}/${this.$route.name}`);
      this.$router.push(
        `/${data}${this.$route.fullPath.slice(3, this.$route.fullPath.length)}`
      );
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.nav {
  height: 80px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*********************** Header ***********************/
.header {
  position: fixed;
  z-index: 3000;
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #1f2833;
  .logo,
  .links {
    display: flex;
    justify-content: center;
    width: calc(100% / 2);
  }
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 50px;
  height: 50px;
}
.logo span {
  margin: 1rem;
  color: #dfe0e2;
  font-size: 20px;
  font-weight: 700;
}
.links_list {
  display: flex;
  list-style: none;
}
.links_list .link {
  display: flex;
  align-items: center;
  margin: 1.2rem;
}
.link a {
  color: #dfe0e2;
  font-weight: 700;
  transition: all 250ms ease-in-out;
  i {
    font-size: 24px;
  }
}
.link a:hover {
  color: #472bff;
}
.openMobileNavBtn {
  display: none;
}
.line {
  width: 35px;
  height: 3px;
  margin: 10px;
  background-color: #dfe0e2;
}
.mobile-nav {
  position: fixed;
  top: 80px;
  width: 100%;
  ul {
    display: none;
    list-style: none;
  }
  ul li {
    text-align: center;
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
@media screen and (max-width: 1573px) {
  .search_input {
    width: 400px;
  }
}
@media screen and (max-width: 1292px) {
  .search_input {
    width: 340px;
  }
}
@media screen and (max-width: 1096px) {
  .search_input {
    width: 200px;
  }
}
@media screen and (max-width: 700px) {
  .search {
    width: 0px;
  }
  .search_input {
    display: none;
  }
  .search_btn,
  .links_list {
    display: none;
  }
  .openMobileNavBtn {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 700px) {
  .show {
    display: none;
  }
}
/*********************** Header ***********************/
/*********************** User info ***********************/
.info {
  display: flex;
  margin-top: 2rem;
  div {
    margin: 1rem;
  }
}
.links1 {
  margin-top: 2rem;
  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  ul {
    margin-top: 3rem;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.update_link {
  color: #1f2833;
  font-size: 17px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  padding: 20px;
  background-color: rgba(128, 128, 128, 0.511);
  border-bottom: 3px solid #472bff;
  &:hover {
    color: #472bff;
  }
}
.update_link.router-link-active {
  background-color: rgba(128, 128, 128, 0.938);
}
/*********************** User info ***********************/
.d {
  margin-top: 4rem;
}
</style>
