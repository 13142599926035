import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// Auth Pages
import LoginView from "../views/Auth/LoginView.vue";
import SignupView from "../views/Auth/SignupView.vue";

// Main Pages
import HomeView from "../views/HomeView.vue";
import ShopView from "../views/ShopView.vue";
import ProductView from "../views/ProductView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";

// User Pages
import ProfileView from "../views/User/ProfileView.vue";
import UpdateName from "../views/User/UpdateName.vue";
import UpdatePassword from "../views/User/UpdatePassword.vue";
import UpdateAddress from "../views/User/UpdateAddress.vue";
import UpdateEmail from "../views/User/UpdateEmail.vue";
import ShoppingCart from "../views/User/ShoppingCart.vue";
import UserOrders from "../views/User/UserOrders.vue";

// Admin Pages
import AdminView from "../views/Admin/AdminView.vue";
import AdminDashboard from "../views/Admin/AdminDashboard.vue";
import ManageCategories from "../views/Admin/ManageCategories.vue";
import ManageProducts from "../views/Admin/ManageProducts.vue";
import ManageOrders from "../views/Admin/ManageOrders.vue";
import Sales from "../views/Admin/SalesView.vue";
import AddProduct from "../views/Admin/AddProduct.vue";
import EditProduct from "../views/Admin/EditProduct.vue";
import OrderView from "../views/Admin/OrderView.vue";

import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "PrivacyPolicy",
        name: "Privacy",
        component: PrivacyPolicy,
      },
      {
        path: "Login",
        name: "Login",
        component: LoginView,
        meta: {
          requiresVisitor: true,
        },
      },
      {
        path: "Signup",
        name: "Signup",
        component: SignupView,
        meta: {
          requiresVisitor: true,
        },
      },
      {
        path: "Shop",
        name: "Shop",
        component: ShopView,
      },
      {
        path: "Product/:id",
        name: "Product",
        component: ProductView,
      },
      {
        path: "Profile",
        name: "Profile",
        component: ProfileView,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "UpdateName",
            name: "UpdateName",
            component: UpdateName,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "UpdatePassword",
            name: "UpdatePassword",
            component: UpdatePassword,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "UpdateAddress",
            name: "UpdateAddress",
            component: UpdateAddress,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "UpdateEmail",
            name: "UpdateEmail",
            component: UpdateEmail,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "Orders",
        name: "UserOrders",
        component: UserOrders,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "Admin",
        name: "Admin",
        component: AdminView,
        meta: {
          requiresAdmin: true,
        },
        children: [
          {
            path: "Dashboard",
            name: "Dashboard",
            component: AdminDashboard,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "Categories",
            name: "Categories",
            component: ManageCategories,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "Products",
            name: "Products",
            component: ManageProducts,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "AddProduct",
            name: "AddProduct",
            component: AddProduct,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "EditProduct/:id",
            name: "EditProduct",
            component: EditProduct,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "Orders",
            name: "Orders",
            component: ManageOrders,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "Order/:id",
            name: "Order",
            component: OrderView,
            meta: {
              requiresAdmin: true,
            },
          },
          {
            path: "Sales",
            name: "Sales",
            component: Sales,
            meta: {
              requiresAdmin: true,
            },
          },
        ],
      },
      {
        path: "Cart",
        name: "Cart",
        component: ShoppingCart,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/ar",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const isLoggedIn = store.getters.isLoggedIn;
const isAdmin = store.getters.isAdminLoggedIn;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isLoggedIn || isAdmin) {
      next(`/${i18n.locale}`);
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLoggedIn) {
      next();
    } else {
      next(`/${i18n.locale}/Login`);
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (isAdmin) {
      next();
    } else if (!isAdmin && isLoggedIn) {
      next(`/${i18n.locale}/Profile`);
    } else {
      next(`/${i18n.locale}`);
    }
  } else {
    next();
  }
});

export default router;
