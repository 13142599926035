<template>
  <div class="cart">
    <!--================= Header =================-->
    <div class="nav">
      <NavBar />
    </div>
    <!--================= Header =================-->
    <!--================= Shopping Cart =================-->
    <div class="shopping_cart">
      <h2 class="section_title" v-if="!success">
        {{ $t("ShoppingCart.Title") }}
      </h2>

      <div class="sc" v-if="!success">
        <div class="container">
          <div class="line1">
            <div class="product_name">{{ $t("ShoppingCart.Name") }}</div>
            <div class="product_qty">{{ $t("ShoppingCart.Quantity") }}</div>
            <div class="remove_product">{{ $t("ShoppingCart.Remove") }}</div>
            <div class="product_price">{{ $t("ShoppingCart.Price") }}</div>
          </div>
          <div
            class="product"
            v-for="product in products"
            v-bind:key="product.productID"
          >
            <div class="product_name">
              <img :src="product.image" class="product_img" />
            </div>
            <div class="product_qty">
              <div class="manage_qty">
                <button v-on:click="increase(product.productID)">
                  <i class="fa-solid fa-plus"></i>
                </button>
                <div class="qty">{{ product.quantity }}</div>
                <button
                  v-on:click="decrease(product.productID)"
                  class="minus_btn"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="remove_product">
              <button
                class="remove_btn"
                v-on:click="removeFromCart(product.productID)"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="product_price">
              {{ Math.round(product.price * product.quantity) }} ₪
            </div>
          </div>
          <div class="load">
            <div class="loader" v-if="loadingCart"></div>
          </div>
          <div class="endline" v-if="products.length != 0">
            <div class="product_name"></div>
            <div class="product_qty"></div>
            <div class="remove_product">
              <h3>{{ $t("ShoppingCart.totalPrice") }}</h3>
            </div>
            <div class="product_price">{{ Math.round(total) }} ₪</div>
          </div>
          <div class="order_btns">
            <div class="address_form">
              <div>
                <h3>{{ $t("ShoppingCart.deliveryInformation") }}</h3>
                <br />
                <div class="input1">
                  <div class="showAddresses" v-on:click="openDropdown()">
                    {{ $t("ShoppingCart.ChooseAddress") }} {{ address.name }}
                  </div>
                  <div class="addresses_list" v-if="dropdown">
                    <div
                      class="address"
                      v-for="address in addresses"
                      v-bind:key="address.id"
                      v-on:click="selectAddress(address)"
                    >
                      {{ address.name }}
                    </div>
                    <div class="address" v-on:click="showAddAddressPopup()">
                      {{ $t("ShoppingCart.addNewAddress") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order_btns">
            <div>
              <button class="checkout_btn" v-on:click="placeOrder()">
                {{ $t("ShoppingCart.Order") }}
                <i class="fa-solid fa-basket-shopping"></i>
              </button>
              <br />
              <button v-on:click="goToShop()" class="continue_btn">
                {{ $t("ShoppingCart.Shopping") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="success_msg" v-if="success">
        <div>
          <h2>{{ $t("ShoppingCart.success1") }}</h2>
          <span>{{ $t("ShoppingCart.msg") }}</span>
          <br />
          <h4>
            <router-link :to="{ name: 'UserOrders' }" class="link">
              {{ $t("ShoppingCart.link") }}</router-link
            >
          </h4>
        </div>
      </div>
      <div class="info">
        <span>
          {{ $t("ShoppingCart.note") }}
          {{ address.name }} {{ address.description }}
        </span>
      </div>
    </div>
    <!--================= Shopping Cart =================-->

    <!--================= Add Address Form =================-->
    <div id="AddAddressForm" v-if="showPopup">
      <div class="dispage">
        <div class="popupContainer">
          <div class="close">
            <button class="exit_button" v-on:click="closeAddAddressPopup()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div class="form">
            <div>
              <div class="input">
                <h1 class="wel_msg">{{ $t("LoginPage.Welcome") }}</h1>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("ShoppingCart.AddAddressPopup.inputTitle1")
                }}</label>
                <br />
                <input
                  type="text"
                  id="addressname_input"
                  class="text-input"
                  :placeholder="$t('ShoppingCart.AddAddressPopup.placeholder1')"
                  v-model="addressName"
                />
                <br />
                <label class="input_err" id="addressname_err"></label>
              </div>
              <div class="input">
                <label class="input_title">{{
                  $t("ShoppingCart.AddAddressPopup.inputTitle2")
                }}</label>
                <br />
                <input
                  type="text"
                  id="address_description_input"
                  class="text-input"
                  :placeholder="$t('ShoppingCart.AddAddressPopup.placeholder2')"
                  v-model="addressDescription"
                />
                <br />
                <label class="input_err" id="address_description_err"></label>
              </div>
              <div class="input">
                <button type="submit" class="btn" v-on:click="addAddress()">
                  {{ $t("ShoppingCart.AddAddressPopup.addBtn") }}
                </button>
              </div>
              <div class="input center">
                <span class="add_address"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================= Add Address Form =================-->

    <!--================= Place Order =================-->
    <!--================= Place Order =================-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      Qty: 1,
      dropdown: false,
      products: [],
      addresses: [],
      address: {},
      total: 0,
      showPopup: false,
      addressName: "",
      addressDescription: "",
      success: false,
      status: [],
      statu: {},
      loadingCart: true,
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let Page = document.getElementsByClassName("cart")[0];
      Page.style.direction = "ltr";
    }
    this.loadStatus();
    this.loadCart();
    this.loadAddress();
  },
  methods: {
    openDropdown() {
      if (this.dropdown == false) {
        this.dropdown = true;
        $(".showAddresses").addClass("remove_bottom_border");
      } else {
        this.dropdown = false;
        $(".showAddresses").removeClass("remove_bottom_border");
      }
    },
    selectAddress(address) {
      this.address = address;
      $(".showAddresses").removeClass("remove_bottom_border");
      $(".showAddresses").css(
        "border",
        "1.5px solid rgba(180, 180, 180, 0.507)"
      );
      this.dropdown = false;
    },
    async loadStatus() {
      await axios
        .get(ApiUrl.url + "/api/Status", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.status = res.data;
        });
    },
    async loadCart() {
      await axios
        .get(ApiUrl.url + "/api/Cart/", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.products = res.data.products;
          this.loadingCart = false;
          this.calcTotal();
        });
    },
    async loadAddress() {
      await axios
        .get(ApiUrl.url + "/api/Address", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.addresses = res.data;
        });
    },
    decrease(productId) {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].productID == productId) {
          if (this.products[i].quantity > 1) {
            this.products[i].quantity -= 1;
          }
        }
      }
      this.calcTotal();
    },
    increase(productId) {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].productID == productId) {
          this.products[i].quantity += 1;
        }
      }
      this.calcTotal();
    },
    goToShop() {
      this.$router.push({ name: "Shop" });
    },
    async removeFromCart(productID) {
      await axios
        .post(
          ApiUrl.url + "/api/Cart/RemoveFromCart",
          { productID: productID },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < this.products.length; i++) {
              this.total -= this.products[i].price * this.products[i].quantity;
              this.products.splice(i, 1);
            }
          }
        });
    },
    calcTotal() {
      let totalOrder = 0;
      for (let i = 0; i < this.products.length; i++) {
        totalOrder += this.products[i].price * this.products[i].quantity;
      }
      this.total = totalOrder;
    },
    async SetQuantity(product) {
      await axios
        .post(
          ApiUrl.url + "/api/Cart/SetQuantity",
          {
            productID: product.productID,
            quantity: product.quantity,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
    },
    isObjEmpty(obj) {
      return Object.values(obj).length === 0 && obj.constructor === Object;
    },
    async placeOrder() {
      for (let i = 0; i < this.status.length; i++) {
        if (this.status[i].name == "Pending") {
          this.statu = this.status[i];
        }
      }
      if (this.isObjEmpty(this.address)) {
        $(".showAddresses").css("border", "1.5px solid rgb(250,82,82)");
        return;
      }
      if (!this.isObjEmpty(this.address) && this.products.length != 0) {
        for (let i = 0; i < this.products.length; i++) {
          this.SetQuantity(this.products[i]);
        }
        await axios
          .post(
            ApiUrl.url + "/api/Orders",
            { addressID: this.address.id.toString(), statusID: this.statu.id },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.success = true;
            }
          });
      }
    },
    showAddAddressPopup() {
      this.showPopup = true;
      setTimeout(() => {
        $("#AddAddressForm").fadeIn(200);
      }, 0);
    },
    closeAddAddressPopup() {
      $("#AddAddressForm").fadeOut(200);
      setTimeout(() => {
        this.showPopup = false;
      }, 1000);
    },
    async addAddress() {
      $("#addressname_input").click(function () {
        window.onkeyup = function () {
          $("#addressname_input").css("border", "1px solid rgb(214, 214, 214)");
          $("#addressname_err").text("");
        };
      });

      $("#address_description_input").click(function () {
        window.onkeyup = function () {
          $("#address_description_input").css(
            "border",
            "1px solid rgb(214, 214, 214)"
          );
          $("#address_description_err").text("");
        };
      });

      if (this.addressName == "" && this.addressDescription == "") {
        $("#addressname_input").css("border", "1px solid rgb(250,82,82)");
        $("#addressname_err").text(
          this.$i18n.t("ShoppingCart.AddAddressPopup.err1")
        );

        $("#address_description_input").css(
          "border",
          "1px solid rgb(250,82,82)"
        );
        $("#address_description_err").text(
          this.$i18n.t("ShoppingCart.AddAddressPopup.err1")
        );

        return;
      }

      if (this.addressName == "") {
        $("#addressname_input").css("border", "1px solid rgb(250,82,82)");
        $("#addressname_err").text(
          this.$i18n.t("ShoppingCart.AddAddressPopup.err1")
        );
        return;
      }
      if (this.addressDescription == "") {
        $("#address_description_input").css(
          "border",
          "1px solid rgb(250,82,82)"
        );
        $("#address_description_err").text(
          this.$i18n.t("ShoppingCart.AddAddressPopup.err1")
        );
        return;
      }

      await axios
        .post(
          ApiUrl.url + "/api/Address",
          { name: this.addressName, description: this.addressDescription },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".add_address").text(
              this.$i18n.t("ShoppingCart.AddAddressPopup.success1")
            );
            this.closeAddAddressPopup();
            this.loadAddress();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.nav {
  height: 80px;
}
/*********************** Shopping Cart ***********************/
.shopping_cart {
  padding: 2.5rem;
}
.section_title {
  text-align: center;
}
.sc {
  display: flex;
  justify-content: center;
}
.container {
  width: 1000px;
  margin-top: 2.5rem;
}
.line1 {
  display: flex;
  height: 60px;
  border-bottom: 1px solid rgba(180, 180, 180, 0.507);
  padding: 1.2rem;
  .product_name {
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #424242;
    align-items: center;
    width: 300px;
  }
  .product_qty,
  .remove_product,
  .product_price {
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #424242;
    align-items: center;
    width: calc(700px / 3);
  }
}
.product {
  display: flex;
  border-bottom: 1px solid rgba(180, 180, 180, 0.507);
  padding: 1.2rem;
  .product_name {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    font-weight: 700;
  }
  .product_qty,
  .remove_product,
  .product_price {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(700px / 3);
  }
  .product_price {
    font-size: 24px;
    color: #424242;
    font-weight: 600;
  }
}
.product_img {
  max-width: 300px;
}
.manage_qty {
  display: flex;
  background-color: #eeeeee;
  border: 0.3px solid rgba(180, 180, 180, 0.507);
  border-radius: 6px;
  button {
    width: 50px;
    height: 50px;
    background-color: #472bff;
    color: #fff;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #3721c5;
    }
  }
  .minus_btn {
    background-color: #b3b3b3;
    color: #424242;
    &:hover {
      background-color: #939393;
    }
  }
}
.qty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.remove_btn {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(180, 180, 180, 0.507);
  font-size: 18px;
  color: #424242;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #472bff;
    border: 1px solid #472bff;
  }
}
.endline {
  display: flex;
  height: 60px;
  border-top: 1px solid rgba(180, 180, 180, 0.507);
  padding: 1.2rem;
  .product_name {
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #424242;
    align-items: center;
    width: 300px;
    margin-top: 20px;
  }
  .product_qty,
  .remove_product,
  .product_price {
    display: flex;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    color: #424242;
    align-items: center;
    width: calc(700px / 3);
    margin-top: 20px;
  }
  .product_price {
    font-size: 24px;
    color: #424242;
    font-weight: 600;
  }
}

/*********************** Shopping Cart ***********************/
/*********************** Choosing Address ***********************/
.addresses {
  margin-top: 2rem;
  padding: 1rem;
}
/*********************** Choosing Address ***********************/

/*********************** Place Order ***********************/
.order_btns {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  margin: 4rem;
}
.checkout_btn {
  margin: 1rem;
  width: 265px;
  height: 50px;
  background-color: #472bff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.continue_btn {
  margin: 1rem;
  width: 265px;
  height: 50px;
  background-color: #6c6c6c;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    cursor: pointer;
    background-color: #3e3e3e;
  }
}
/*********************** Place Order ***********************/
.address_form {
  width: 335px;
}
.input1 {
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.showAddresses {
  width: 335px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:hover {
    cursor: pointer;
  }
}
.remove_bottom_border {
  border-bottom: none;
}
.addresses_list {
  border: 1.5px solid rgba(180, 180, 180, 0.507);
  width: 335px;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.address {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: #eeeeee;
    background-color: #472bff;
  }
}
.address:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
#AddAddressForm {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 5000;
  transform: translate(-50%, -50%);
  display: none;
}
.dispage {
  width: 100%;
  height: 100vh;
  background-color: #1f283398;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupContainer {
  width: 600px;
  height: 550px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.close {
  text-align: right;
  width: 100%;
  height: 50px;
}
.exit_button {
  margin: 1rem;
  background: none;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  color: #1f2833;
  font-size: 24px;
  transition: all 300ms ease-in-out;
}
.exit_button:hover {
  cursor: pointer;
  color: #472bff;
}
.form {
  height: calc(70% - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input {
  padding: 1rem;
}
.wel_msg {
  text-align: center;
}
.input_title {
  color: rgb(4, 4, 4);
  font-weight: 600;
  font-size: 18px;
}
.text-input {
  width: 370px;
  height: 40px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 10px;
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px 100px 5px rgba(0, 0, 0, 0.1);
  color: rgb(4, 4, 4);
  transition: all 300ms ease-in-out;
}
.text-input:focus {
  border: 1px solid #472bff;
}
.text-input-direction {
  padding-left: 0;
  padding-right: 10px;
}
.text-input::placeholder {
  color: rgb(214, 214, 214);
}
.input_err {
  color: rgb(250, 82, 82);
  font-weight: 600;
  font-size: 16px;
}
.btn {
  width: 370px;
  height: 40px;
  background-color: #472bff;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3d26d8;
  }
}
.info {
  display: flex;
  justify-content: center;
}
.success_msg {
  display: flex;
  justify-content: center;
  h2,
  h4 {
    text-align: center;
  }
}
.link {
  color: #472bff;
  &:hover {
    text-decoration: underline;
  }
}
.load {
  display: flex;
  justify-content: center;
}
.loader {
  width: 35px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(farthest-side, #472bff 94%, #0000);
  background: var(--_g) 0 0, var(--_g) 100% 0, var(--_g) 100% 100%,
    var(--_g) 0 100%;
  background-size: 40% 40%;
  animation: l38 0.5s infinite;
}
@keyframes l38 {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
</style>
