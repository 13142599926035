<template>
  <div class="orderView">
    <h2 class="section_title">
      {{ $t("AdminPage.OrderView.Title") }} {{ this.$route.params.id }}
    </h2>
    <!--============== Customer Info ==============-->
    <div class="customer_info">
      <div class="name">
        <h3>{{ $t("AdminPage.OrderView.customername") }} : {{ name }}</h3>
      </div>
      <div class="phone">
        <h3>{{ $t("AdminPage.OrderView.phone") }} : {{ phonenumber }}</h3>
      </div>
      <div class="address">
        <div class="address_name">
          <h3>
            {{ $t("AdminPage.OrderView.AddressTitle") }} : {{ address.name }}
          </h3>
        </div>
        <div class="address_description">
          <h3>
            {{ $t("AdminPage.OrderView.AddressDescription") }}
            :
            {{ address.description }}
          </h3>
        </div>
      </div>
    </div>
    <!--============== Customer Info ==============-->
    <!--============== Order info ==============-->
    <div class="pro">
      <div class="products">
        <div class="product firstrow">
          <div class="image_column">
            {{ $t("AdminPage.OrderView.productImage") }}
          </div>
          <div class="info_column">
            {{ $t("AdminPage.OrderView.productInfo") }}
          </div>
          <div class="q_column">{{ $t("AdminPage.OrderView.Quantity") }}</div>
          <div class="total_column">{{ $t("AdminPage.OrderView.price") }}</div>
        </div>
        <div
          class="product"
          v-for="product in products"
          v-bind:key="product.productID"
        >
          <div class="product_image">
            <img :src="product.image" />
          </div>
          <div class="product_info">
            <h3 class="product_info_name">
              {{ product.name }}
            </h3>
          </div>
          <div class="product_q">{{ product.quantity }}</div>
          <div class="product_total">
            {{ product.quantity * product.price }} ₪
          </div>
        </div>
      </div>
    </div>
    <div class="totalprice_finishorder">
      <h3>{{ $t("AdminPage.OrderView.totalPrice") }} {{ total }}</h3>
      <div class="updateStatus">
        <div class="dropdown">
          <button class="dropbtn" v-on:click="showStatusDropdown()">
            {{ $t("AdminPage.OrderView.updateStatus") }}
          </button>
          <div class="dropdown-content">
            <div v-for="state in stat" v-bind:key="state.id">
              <span
                v-if="state.name == 'Pending'"
                v-on:click="updateOrderStatus(state)"
                >{{ $t("AdminPage.OrderView.status1") }}</span
              >
              <span
                v-if="state.name == 'On the way'"
                v-on:click="updateOrderStatus(state)"
                >{{ $t("AdminPage.OrderView.status2") }}</span
              >
              <span
                v-if="state.name == 'Done'"
                v-on:click="updateOrderStatus(state)"
                >{{ $t("AdminPage.OrderView.status3") }}</span
              >
              <span
                v-if="state.name == 'Failed'"
                v-on:click="updateOrderStatus(state)"
                >{{ $t("AdminPage.OrderView.status4") }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <h3 v-if="status.name == 'Done'">
        {{ $t("AdminPage.OrderView.orderIsClosed") }}
      </h3> -->
    </div>
    <!--============== Order info ==============-->
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "../../ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  data() {
    return {
      orderDate: "",
      status: {},
      products: [],
      address: {},
      total: 0,
      phonenumber: "",
      name: "",
      stat: [],
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("orderView")[0];
    let section_title = document.getElementsByClassName("section_title");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    }
    this.loadOrder();
    this.loadStatus();
  },
  methods: {
    async loadStatus() {
      await axios
        .get(ApiUrl.url + "/api/Status", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res);
          this.stat = res.data;
        });
    },
    async loadOrder() {
      await axios
        .get(ApiUrl.url + `/api/Orders/${this.$route.params.id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.orderDate = res.data.date;
          this.products = res.data.products;
          this.address = res.data.address;
          this.phonenumber = res.data.user.phone;
          this.name = res.data.user.name;
          this.total = res.data.total;
          this.status = res.data.status;
        })
        .catch((err) => {
          if (err) {
            this.backToOrders();
          }
        });
    },
    showStatusDropdown() {
      $(".dropdown-content").show();
    },
    backToOrders() {
      this.$router.push({ name: "Orders" });
    },
    async updateOrderStatus(status) {
      await axios
        .post(
          ApiUrl.url + "/api/Orders/SetStatus",
          {
            orderNo: this.$route.params.id,
            statusID: status.id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.backToOrders();
          }
        });
    },
  },
};
window.onclick = function (e) {
  if (!(e.target.classList[0] == "dropbtn")) {
    $(".dropdown-content").hide();
  }
};
</script>

<style lang="scss" scoped>
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}
/*************** Customer Info ***************/
.customer_info {
  display: flex;
  .name,
  .phone {
    display: flex;
  }
  .name,
  .phone,
  .address {
    margin: 1rem;
  }
}
.address_name {
  display: flex;
  align-items: center;
}
.address_description {
  display: flex;
  align-items: center;
}
/*************** Customer Info ***************/
.products {
  border-radius: 15px;
}
.firstrow {
  height: 50px;
  .image_column,
  .info_column,
  .q_column,
  .total_column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
  }
  .image_column {
    width: 30%;
  }
  .info_column {
    width: 30%;
  }
  .q_column {
    width: 10%;
  }
  .total_column {
    width: 15%;
  }
}
.product {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgb(170, 170, 170);
}
.product:last-child {
  border-bottom: none;
}
.product {
  div {
    border-right: 1px solid rgb(170, 170, 170);
  }
}
.product_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  img {
    max-width: 300px;
  }
}
.product_info {
  padding: 10px;
  width: 30%;
}
.product_info_name {
  height: 40px;
}
.product_q {
  width: 10%;
}

.product_q,
.product_total {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 15px;
}
.product_total {
  width: 15%;
}
.totalprice_finishorder {
  margin: 1.5rem;
  margin-top: 3rem;
  text-align: center;
}
.dropbtn {
  margin-top: 3rem;
  width: 140px;
  height: 50px;
  background: #472bff;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3622bd;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: all 300ms ease-in-out;
}
.dropdown-content div:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
</style>
