<template>
  <div class="AddProduct">
    <h2 class="section_title">{{ $t("AdminPage.AddProduct.Title") }}</h2>
    <!--================== Add Product Form ==================-->
    <div class="f">
      <div class="product_info">
        <!--=========== Product Arabic Name ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title1")
            }}</label>
            <br />
            <input
              type="text"
              class="text-input"
              id="product_arabic_name"
              v-model="productNameAR"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder1')"
            />
            <br />
            <label class="error" id="product_arabic_name_label"></label>
          </div>
        </div>

        <!--=========== Product Arabic Description ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title2")
            }}</label>
            <br />
            <input
              type="text"
              class="text-input"
              id="product_arabic_description"
              v-model="productDescriptionAR"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder2')"
            />
            <br />
            <label class="error" id="product_arabic_description_label"></label>
          </div>
        </div>

        <!--=========== Product English Name ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title3")
            }}</label>
            <br />
            <input
              type="text"
              class="text-input"
              id="product_english_name"
              v-model="productNameEN"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder3')"
            />
            <br />
            <label class="error" id="product_english_name_label"></label>
          </div>
        </div>

        <!--=========== Product English Description ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title4")
            }}</label>
            <br />
            <input
              type="text"
              class="text-input"
              id="product_english_description"
              v-model="productDescriptionEN"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder4')"
            />
            <br />
            <label class="error" id="product_english_description_label"></label>
          </div>
        </div>

        <!--=========== Product Price ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title5")
            }}</label>
            <br />
            <input
              type="number"
              class="text-input"
              id="product_price"
              v-model="productprice"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder5')"
            />
            <br />
            <label class="error" id="product_price_label"></label>
          </div>
        </div>

        <!--=========== Selected Category ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title6")
            }}</label>
            <br />
            <input
              type="text"
              class="text-input no-mar"
              id="selectedCategory_input"
              v-model="query"
              autocomplete="off"
              @keydown="searchCategory()"
              :placeholder="$t('AdminPage.AddProduct.placeholder6')"
            />
            <div class="list_cate" v-if="!itsSelected">
              <div
                class="category"
                v-for="category in Categories"
                v-bind:key="category.id"
                v-on:click="
                  selectCategory(category.id, category.name, category.icon)
                "
              >
                <div class="c_i"><i :class="category.icon"></i></div>
                <div class="c_n">{{ category.name }}</div>
              </div>
            </div>
            <br />
            <label class="error" id="selectedCategory_input_label"></label>
          </div>
        </div>

        <!--=========== The Selected Category ===========-->
        <div class="input" v-if="itsSelected">
          <div class="selecC">
            <div class="c_i">
              <i :class="SelectedCategories.icon"></i>
            </div>
            <div class="c_name">
              {{ SelectedCategories.name }}
            </div>
            <div class="remove_btn">
              <button class="deselectCat" v-on:click="deSelectCategory()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>

        <!--=========== is discounted ===========-->
        <div class="input">
          <div>
            <label>
              {{ $t("AdminPage.AddProduct.title7") }}
            </label>
            <div class="activate_btn" v-on:click="updateA()">
              <i class="fa-solid fa-check"></i>
            </div>
          </div>
        </div>

        <!--=========== discount rate ===========-->
        <div class="input">
          <div>
            <label class="input-title">{{
              $t("AdminPage.AddProduct.title8")
            }}</label>
            <br />
            <input
              type="number"
              class="text-input"
              id="discount_rate"
              v-model="discountpercent"
              autocomplete="off"
              :placeholder="$t('AdminPage.AddProduct.placeholder8')"
            />
            <br />
            <label class="error" id="discount_rate_label"></label>
          </div>
        </div>

        <div class="input">
          <button class="addBtn" v-on:click="addProduct()">
            {{ $t("AdminPage.AddProduct.Title") }}
          </button>
        </div>
      </div>
      <!-- Image -->
      <div class="product_image">
        <div class="input">
          <div class="choose_file">
            <label for="choose_file">
              <input
                type="file"
                name="Images"
                multiple
                ref="file"
                @change="loadImage($event)"
                id="choose_file"
              />
              <span class="file_err">{{
                $t("AdminPage.AddProduct.placeholder7")
              }}</span>
            </label>
          </div>
        </div>
        <cropper
          ref="cropper"
          class="upload-example-cropper"
          :src="image.src"
        />
      </div>
    </div>
    <!--================== Add Product Form ==================-->
    <!--============= Message box Section =============-->
    <div class="Message1" id="MessageBox">
      <div class="message_contaier">
        <div class="MessContent">
          <p id="msg"></p>
        </div>
        <div class="closeMsg">
          <button class="closeBtn" v-on:click="closeMsg()">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <!--============= Message box Section =============-->
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "../../ApiUrl";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import jquery from "jquery";
let $ = jquery;
function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

export default {
  data() {
    return {
      Categories: [],
      SelectedCategories: {},
      query: "",
      itsSelected: false,
      image: {
        src: null,
        type: null,
      },
      productNameAR: "",
      productDescriptionAR: "",
      productNameEN: "",
      productDescriptionEN: "",
      productprice: 0,
      isdiscounted: false,
      discountpercent: 0,
    };
  },
  components: {
    Cropper,
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("AddProduct")[0];
    let section_title = document.getElementsByClassName("section_title");
    let msg_box = document.getElementById("MessageBox");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
      msg_box.classList.add("Message1R");
    } else {
      msg_box.classList.add("Message1L");
    }
    await axios
      .get(ApiUrl.url + "/api/Categories/", {
        headers: {
          "Accept-Language": this.$i18n.locale,
        },
      })
      .then((res) => {
        this.Categories = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    selectCategory(id, cname, icon) {
      let obj = {
        id: id,
        name: cname,
        icon: icon,
      };
      $("#selectedCategory_input").css(
        "border",
        "1px solid rgb(163, 163, 163)"
      );
      $("#selectedCategory_input_label").text("");
      this.SelectedCategories = obj;
      this.itsSelected = true;
    },
    deSelectCategory() {
      this.SelectedCategories = {};
      this.itsSelected = false;
    },
    searchCategory() {
      axios
        .get(ApiUrl.url + `/api/Categories/Search?query=${this.query}`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          this.Categories = res.data;
        });
    },
    loadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }

        const blob = URL.createObjectURL(files[0]);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    updateA() {
      if (this.isdiscounted == false) {
        this.isdiscounted = true;
        $(".activate_btn").css("backgroundColor", "#472bff");
      } else {
        this.isdiscounted = false;
        $(".activate_btn").css("backgroundColor", "#fff");
      }
    },
    addProduct() {
      $("#product_arabic_name").click(function () {
        window.onkeydown = function () {
          $("#product_arabic_name").css(
            "border",
            "1px solid rgb(163, 163, 163)"
          );
          $("#product_arabic_name_label").text("");
        };
      });

      $("#product_arabic_description").click(function () {
        window.onkeydown = function () {
          $("#product_arabic_description").css(
            "border",
            "1px solid rgb(163, 163, 163)"
          );
          $("#product_arabic_description_label").text("");
        };
      });

      $("#product_english_name").click(function () {
        window.onkeydown = function () {
          $("#product_english_name").css(
            "border",
            "1px solid rgb(163, 163, 163)"
          );
          $("#product_english_name_label").text("");
        };
      });

      $("#product_english_description").click(function () {
        window.onkeydown = function () {
          $("#product_english_description").css(
            "border",
            "1px solid rgb(163, 163, 163)"
          );
          $("#product_english_description_label").text("");
        };
      });

      $("#product_price").click(function () {
        window.onkeydown = function () {
          $("#product_price").css("border", "1px solid rgb(163, 163, 163)");
          $("#product_price_label").text("");
        };
      });

      $("#discount_rate").click(function () {
        window.onkeydown = function () {
          $("#discount_rate").css("border", "1px solid rgb(163, 163, 163)");
          $("#discount_rate_label").text("");
        };
      });

      if (
        this.productNameAR == "" &&
        this.productDescriptionAR == "" &&
        this.productNameEN == "" &&
        this.productDescriptionEN == "" &&
        this.productprice == 0 &&
        this.itsSelected == false
      ) {
        $("#product_arabic_name").css("border", "1px solid rgb(250,82,82)");
        $("#product_arabic_name_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );

        $("#product_arabic_description").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#product_arabic_description_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );

        $("#product_english_name").css("border", "1px solid rgb(250,82,82)");
        $("#product_english_name_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );

        $("#product_english_description").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#product_english_description_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );

        $("#product_price").css("border", "1px solid rgb(250, 82, 82)");
        $("#product_price_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );

        $("#selectedCategory_input").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#selectedCategory_input_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.productNameAR == "") {
        $("#product_arabic_name").css("border", "1px solid rgb(250,82,82)");
        $("#product_arabic_name_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.productDescriptionAR == "") {
        $("#product_arabic_description").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#product_arabic_description_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.productNameEN == "") {
        $("#product_english_name").css("border", "1px solid rgb(250,82,82)");
        $("#product_english_name_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.productDescriptionEN == "") {
        $("#product_english_description").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#product_english_description_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.productprice == "") {
        $("#product_price").css("border", "1px solid rgb(250, 82, 82)");
        $("#product_price_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.itsSelected == false) {
        $("#selectedCategory_input").css(
          "border",
          "1px solid rgb(250, 82, 82)"
        );
        $("#selectedCategory_input_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
        return;
      }

      if (this.isdiscounted == true && this.discountpercent == 0) {
        $("#discount_rate").css("border", "1px solid rgb(250, 82, 82)");
        $("#discount_rate_label").text(
          this.$i18n.t("AdminPage.AddProduct.err1")
        );
      }

      const { canvas } = this.$refs.cropper.getResult();

      const fd = new FormData();

      if (canvas) {
        canvas.toBlob((blob) => {
          let file = new File([blob], "product-image.png", { type: blob.type });
          fd.append("arabicname", this.productNameAR);
          fd.append("arabicdescription", this.productDescriptionAR);
          fd.append("englishname", this.productNameEN);
          fd.append("englishdescription", this.productDescriptionEN);
          fd.append("price", this.productprice);
          fd.append("categoryid", this.SelectedCategories.id);
          fd.append("image", file);

          if (this.isdiscounted == true) {
            fd.append("isdiscounted", true);
            fd.append("discountpercent", this.discountpercent);
          } else {
            fd.append("isdiscounted", false);
            fd.append("discountpercent", this.discountpercent);
          }

          axios
            .post(ApiUrl.url + "/api/Products/", fd, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (res.status == 200) {
                $("#msg").text(this.$t("AdminPage.AddProduct.successMsg1"));
                $(".Message1").fadeIn(200);
                setTimeout(() => {
                  $(".Message1").fadeOut(400);
                }, 3000);
              }
            })
            .catch((err) => {
              if (err.status == 400) {
                $("#msg").text(this.$t("AdminPage.AddProduct.err2"));
                $(".Message1").fadeIn(200);
                setTimeout(() => {
                  $(".Message1").fadeOut(400);
                }, 3000);
              }
            });
        });
      } else {
        $(".choose_file label").css("border", "2px dashed rgb(250,82,82)");
      }
    },
    closeMsg() {
      $(".Message1").fadeOut(400);
    },
  },
};
</script>

<style lang="scss" scoped>
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}
.cropper {
  height: 600px;
  background: #ddd;
}

/***************** Add Product Form *****************/
.f {
  margin: 3rem;
  display: flex;
  .product_info,
  .product_image {
    width: calc(100% / 2);
  }
}
.input {
  padding: 0.5rem;
}
.input-title {
  font-size: 18px;
  font-weight: bold;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.6rem;
  transition: all 300ms ease-in-out;
}
.no-mar {
  margin-bottom: 0;
}
.error {
  color: rgb(250, 82, 82);
  font-weight: bold;
}
.list_cate {
  height: 200px;
  width: 365px;
  overflow: hidden;
  overflow-y: auto;

  background: rgb(243, 243, 243);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#selectedCategory_input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.category {
  width: 365px;
  height: 50px;
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
}
.c_i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
}
.c_n {
  display: flex;
  align-items: center;
  width: 300px;
}
.category:hover {
  color: #eeeeee;
  cursor: pointer;
  background-color: #472bff;
}
.selecC {
  display: flex;
  width: 365px;
  height: 50px;
  border-bottom: 3px solid #472bff;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.c_name {
  display: flex;
  align-items: center;
  width: 260px;
}
.remove_btn {
  display: flex;
  align-items: center;
  width: 40px;
}
.deselectCat {
  transition: all 300ms ease-in-out;
  i {
    font-size: 22px;
  }
  &:hover {
    cursor: pointer;
    color: #472bff;
  }
}
.product_image {
  text-align: center;
}
.choose_file label {
  display: block;
  border: 2px dashed #472bff;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}
.choose_file #choose_file {
  outline: none;
  opacity: 0;
  width: 0;
}
.choose_file span {
  font-size: 14px;
  color: #472bff;
}
.choose_file label:hover span {
  text-decoration: underline;
}
.addBtn {
  width: 365px;
  height: 40px;
  background: #472bff;
  border-radius: 5px;
  color: #eeeeee;
  transition: all 300ms ease-in-out;
  font-size: 15px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    background: #3b23d3;
  }
}
/***************** Add Product Form *****************/
/*********************** Message box ***********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  border-left: 10px solid #472bff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.Message1R {
  right: 2%;
}
.Message1L {
  left: 2%;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #472bff;
  }
}
/*********************** Message box ***********************/
.activate_btn {
  text-align: center;
  width: 65px;
  height: 40px;
  padding: 10px;
  background: none;
  outline: none;
  border: none;
  background: #fff;
  color: #fff;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(163, 163, 163);
  &:hover {
    cursor: pointer;
  }
}
</style>
