<template>
  <div>
    <h2>{{ $t("AdminPage.Sales.TheSales") }}</h2>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
