<template>
  <div class="product">
    <!--================= Header =================-->
    <div class="a">
      <div class="nav">
        <NavBar />
      </div>
      <!--================= Header =================-->
      <!--================= Product =================-->
      <div class="info">
        <div class="Images">
          <img :src="image" />
        </div>
        <div class="product_info">
          <div class="product_name">
            <h2 v-if="$i18n.locale == 'en'">{{ nameEN }}</h2>
            <h2 v-if="$i18n.locale == 'ar'">{{ nameAR }}</h2>
          </div>
          <div class="product_description">
            <h3>{{ $t("ProductPage.productDescription") }}</h3>
            <p v-if="$i18n.locale == 'en'">{{ descriptionEN }}</p>
            <p v-if="$i18n.locale == 'ar'">{{ descriptionAR }}</p>
          </div>
          <div class="product_price">
            <h2>
              {{ $t("ProductPage.price") }}
              {{ Math.round(price - (discountRate / 100) * price) }}
              <span class="shikel">₪</span>
            </h2>
          </div>
          <div class="add_to_cart">
            <button class="add_to_cart_button" v-on:click="addToCart()">
              {{ $t("ProductPage.addToCart") }}
              <i class="fa-solid fa-cart-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <!--================= Product =================-->
      <!--================= Message Box =================-->
      <div class="Message1" id="MessageBox">
        <div class="message_contaier">
          <div class="MessContent">
            <p id="msg"></p>
          </div>
          <div class="closeMsg">
            <button class="closeBtn" v-on:click="closeMsg()">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      </div>
      <!--================= More Product Like this =================-->
      <!-- <h2 class="section_title">{{ $t("ProductPage.MoreProducts") }}</h2> -->
      <!--================= More Product Like this =================-->
    </div>
    <div class="footer">
      <footerUser />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import footerUser from "@/components/footerUser.vue";
import axios from "axios";
import ApiUrl from "../ApiUrl";
import jquery from "jquery";
let $ = jquery;
export default {
  components: {
    NavBar,
    footerUser,
  },
  data() {
    return {
      nameEN: "",
      descriptionEN: "",
      nameAR: "",
      descriptionAR: "",
      image: "",
      price: 0,
      isDiscount: false,
      discountRate: 0,
      isLoggedIn: false,
    };
  },
  async mounted() {
    let page = document.getElementsByClassName("product")[0];
    let msg_box = document.getElementById("MessageBox");
    this.isLoggedIn = this.$store.getters.isLoggedIn;
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      msg_box.classList.add("Message1R");
    } else {
      msg_box.classList.add("Message1L");
    }
    this.loadProduct();
  },
  methods: {
    async loadProduct() {
      await axios
        .get(ApiUrl.url + `/api/Products/GetProduct/${this.$route.params.id}`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((res) => {
          console.log(res);
          this.nameEN = res.data.englishName;
          this.descriptionEN = res.data.englishDescription;
          this.nameAR = res.data.arabicName;
          this.descriptionAR = res.data.arabicDescription;
          this.image = res.data.image;
          this.price = res.data.price;
          this.isDiscount = res.data.isDiscounted;
          this.discountRate = res.data.discountPercent;
        });
    },
    async addToCart() {
      if (this.isLoggedIn) {
        await axios
          .post(
            ApiUrl.url + "/api/Cart/AddToCart",
            { productID: this.$route.params.id },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              $("#msg").text(this.$i18n.t("ShopPage.addedToCart"));
              $(".Message1").fadeIn(200);
              setTimeout(() => {
                $(".Message1").fadeOut(400);
              }, 3000);
            }
          });
      } else {
        $("#msg").text(this.$i18n.t("ProductPage.notLoggedIn"));
        $(".Message1").fadeIn(200);
        setTimeout(() => {
          $(".Message1").fadeOut(400);
        }, 3000);
      }
    },
    closeMsg() {
      $(".Message1").fadeOut(400);
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  color: #1f2833;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.nav {
  height: 80px;
}
.a {
  flex: 1;
}
/*********************** Product ***********************/
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  height: 45vh;
}

.Images {
  display: flex;
  justify-content: center;
  img {
    max-width: 800px;
    // max-height: 250px;
  }
}
.product_info {
  margin-left: 3rem;
  margin-right: 3rem;
}
.product_name {
  display: flex;
  height: 50px;
}
.product_description {
  width: 400px;
  p {
    font-weight: 500;
    font-size: 18px;
  }
}
.product_price {
  display: flex;
  align-items: center;
  height: 60px;
}
.shikel {
  color: #472bff;
}
.add_to_cart {
  margin-top: 1rem;
}
.add_to_cart_button {
  width: 400px;
  height: 50px;
  background: #472bff;
  border-radius: 5px;
  color: #eeeeee;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background: #321db9;
  }
}
/*********************** Product ***********************/
/*********************** More Product Like this ***********************/
.section_title {
  margin: 2rem;
}
.more {
  display: flex;
  justify-content: center;
  margin: 2rem;
}
.products {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 30px;
}
.product1 {
  width: 350px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
  transition: all 300ms ease-in-out;
}
.product_img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
.product_link {
  color: #1f2833;
}
.product_info1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
}
.product_name1 {
  width: 150px;
  font-size: 24px;
  text-align: center;
  overflow-wrap: break-word;
}
.price {
  text-align: center;
  width: 150px;
  font-size: 24px;
}
.product1:hover {
  cursor: pointer;
  transform: scale(1.1);
}
@media screen and (max-width: 1650px) {
  .products {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1430px) {
  .products {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1055px) {
  .products {
    grid-template-columns: auto;
  }
}
/*********************** More Product Like this ***********************/
/*********************** Message Box ***********************/
.Message1 {
  width: 400px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: 85%;
  border-left: 10px solid #472bff;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.Message1R {
  right: 2%;
}
.Message1L {
  left: 2%;
}
.message_contaier {
  width: 100%;
  height: 100%;
  display: flex;
}
.MessContent {
  display: flex;
  align-items: center;
  width: 350px;
  p {
    margin: 1rem;
  }
}
.closeMsg {
  display: flex;
  align-items: flex-start;
  padding-top: 0;
  padding: 10px;
}
.closeBtn {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  i {
    color: #1f2833;
    font-size: 22px;
    transition: all 300ms ease-in-out;
  }
}
.closeBtn:hover {
  cursor: pointer;
  i {
    color: #472bff;
  }
}
/*********************** Message Box ***********************/
.footer {
  margin-top: 1rem;
}
</style>
