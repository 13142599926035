<template>
  <div class="s">
    <!--================= Header =================-->
    <header class="header">
      <div class="logo">
        <img src="../assets/logo.png" />
        <span> {{ $t("HomePage.CompanyName") }} </span>
      </div>
      <div class="links">
        <ul class="links_list">
          <li class="link">
            <router-link :to="{ name: 'Dashboard' }">{{
              $t("AdminPage.Navbar.Link1")
            }}</router-link>
          </li>
          <li class="link">
            <router-link :to="{ name: 'Categories' }">{{
              $t("AdminPage.Navbar.Link2")
            }}</router-link>
          </li>
          <li class="link">
            <router-link :to="{ name: 'Products' }">{{
              $t("AdminPage.Navbar.Link3")
            }}</router-link>
          </li>
          <li class="link">
            <router-link :to="{ name: 'Orders' }">{{
              $t("AdminPage.Navbar.Link4")
            }}</router-link>
          </li>
          <li class="link">
            <router-link :to="{ name: 'Sales' }">{{
              $t("AdminPage.Navbar.Link5")
            }}</router-link>
          </li>
          <li class="link" v-if="$i18n.locale == 'en'">
            <a v-on:click="click('ar')">{{ $t("HomePage.Link4") }}</a>
          </li>
          <li class="link" v-if="$i18n.locale == 'ar'">
            <a v-on:click="click('en')">{{ $t("HomePage.Link5") }}</a>
          </li>
        </ul>
        <button class="openMobileNavBtn" v-on:click="openNav()">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </button>
      </div>
    </header>
    <div class="mobile-nav">
      <ul>
        <li class="link">
          <router-link :to="{ name: 'Dashboard' }">{{
            $t("AdminPage.Navbar.Link1")
          }}</router-link>
        </li>
        <li class="link">
          <router-link :to="{ name: 'Categories' }">{{
            $t("AdminPage.Navbar.Link2")
          }}</router-link>
        </li>
        <li class="link">
          <router-link :to="{ name: 'Products' }">{{
            $t("AdminPage.Navbar.Link3")
          }}</router-link>
        </li>
        <li class="link">
          <router-link :to="{ name: 'Orders' }">{{
            $t("AdminPage.Navbar.Link4")
          }}</router-link>
        </li>
        <li class="link" v-if="$i18n.locale == 'en'">
          <a v-on:click="click('ar')">{{ $t("HomePage.Link4") }}</a>
        </li>
        <li class="link" v-if="$i18n.locale == 'ar'">
          <a v-on:click="click('en')">{{ $t("HomePage.Link5") }}</a>
        </li>
      </ul>
    </div>
    <!--================= Header =================-->
  </div>
</template>

<script>
export default {
  async mounted() {
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("s")[0];
      page.style.direction = "ltr";
    }
  },
  methods: {
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    click(data) {
      this.$router.push(
        `/${data}${this.$route.fullPath.slice(3, this.$route.fullPath.length)}`
      );
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.move-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/*********************** Header ***********************/
.s {
  z-index: 4000;
}
.header {
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 4000;
  align-items: center;
  height: 80px;
  background-color: #1f2833;
  .logo,
  .search,
  .links {
    display: flex;
    justify-content: center;
    width: calc(100% / 2);
  }
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 50px;
  height: 50px;
}
.logo span {
  margin: 1rem;
  color: #dfe0e2;
  font-size: 20px;
  font-weight: 700;
}

.links_list {
  display: flex;
  list-style: none;
}
.links_list .link {
  display: flex;
  align-items: center;
  margin: 1.2rem;
}
.link a {
  color: #dfe0e2;
  font-weight: 700;
  transition: all 250ms ease-in-out;
  i {
    font-size: 24px;
  }
}
.link a:hover {
  color: #472bff;
}
.openMobileNavBtn {
  display: none;
}
.line {
  width: 35px;
  height: 3px;
  margin: 10px;
  background-color: #dfe0e2;
}
.mobile-nav {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 4000;

  ul {
    display: none;
    list-style: none;
  }
  ul li {
    text-align: center;
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
@media screen and (max-width: 1573px) {
  .search_input {
    width: 400px;
  }
}
@media screen and (max-width: 1292px) {
  .search_input {
    width: 340px;
  }
}
@media screen and (max-width: 1096px) {
  .search_input {
    width: 200px;
  }
}
@media screen and (max-width: 700px) {
  .search {
    width: 0px;
  }
  .search_input {
    display: none;
  }
  .search_btn,
  .links_list {
    display: none;
  }
  .openMobileNavBtn {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 700px) {
  .show {
    display: none;
  }
}
/*********************** Header ***********************/
</style>
