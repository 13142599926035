<template>
  <div class="s">
    <!--================= Header =================-->
    <header class="header">
      <div class="logo">
        <img src="../assets/logo.png" />
        <span> {{ $t("HomePage.CompanyName") }} </span>
      </div>
      <div class="links">
        <ul class="links_list">
          <li class="link">
            <router-link :to="{ name: 'home' }">{{
              $t("HomePage.Link0")
            }}</router-link>
          </li>
          <li class="link">
            <router-link :to="{ name: 'Shop' }">{{
              $t("HomePage.Link3")
            }}</router-link>
          </li>
          <li class="link" v-if="isLoggedIn">
            <router-link :to="{ name: 'Profile' }">{{
              $t("HomePage.Link6")
            }}</router-link>
          </li>
          <li class="link" v-if="!isLoggedIn">
            <router-link :to="{ name: 'Login' }">{{
              $t("HomePage.Link1")
            }}</router-link>
          </li>
          <li class="link" v-if="!isLoggedIn">
            <router-link :to="{ name: 'Signup' }">{{
              $t("HomePage.Link2")
            }}</router-link>
          </li>
          <li class="link shopping_cart" v-if="isLoggedIn">
            <router-link :to="{ name: 'Cart' }">
              <i class="fa-solid fa-cart-shopping"></i>
            </router-link>
          </li>
          <li class="link" v-if="isLoggedIn" v-on:click="logout()">
            <button class="logout">
              {{ $t("HomePage.Link7") }}
            </button>
          </li>
          <li class="link" v-if="$i18n.locale == 'en'">
            <a v-on:click="click('ar')">{{ $t("HomePage.Link4") }}</a>
          </li>
          <li class="link" v-if="$i18n.locale == 'ar'">
            <a v-on:click="click('en')">{{ $t("HomePage.Link5") }}</a>
          </li>
        </ul>
        <button class="openMobileNavBtn" v-on:click="openNav()">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </button>
      </div>
    </header>
    <div class="mobile-nav">
      <ul>
        <li class="link">
          <router-link :to="{ name: 'home' }">{{
            $t("HomePage.Link0")
          }}</router-link>
        </li>
        <li class="link">
          <router-link :to="{ name: 'Shop' }">{{
            $t("HomePage.Link3")
          }}</router-link>
        </li>
        <li class="link" v-if="isLoggedIn">
          <router-link :to="{ name: 'Profile' }">{{
            $t("HomePage.Link6")
          }}</router-link>
        </li>
        <li class="link" v-if="!isLoggedIn">
          <router-link :to="{ name: 'Login' }">{{
            $t("HomePage.Link1")
          }}</router-link>
        </li>
        <li class="link" v-if="!isLoggedIn">
          <router-link :to="{ name: 'Signup' }">{{
            $t("HomePage.Link2")
          }}</router-link>
        </li>
        <li class="link shopping_cart" v-if="isLoggedIn">
          <router-link :to="{ name: 'Cart' }">
            <i class="fa-solid fa-cart-shopping"></i>
          </router-link>
        </li>
        <li class="link" v-if="isLoggedIn" v-on:click="logout()">
          <button class="logout">
            {{ $t("HomePage.Link7") }}
          </button>
        </li>
        <li class="link" v-if="$i18n.locale == 'en'">
          <a v-on:click="click('ar')">{{ $t("HomePage.Link4") }}</a>
        </li>
        <li class="link" v-if="$i18n.locale == 'ar'">
          <a v-on:click="click('en')">{{ $t("HomePage.Link5") }}</a>
        </li>
      </ul>
    </div>
    <!--================= Header =================-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  async mounted() {
    this.isLoggedIn = this.$store.getters.isLoggedIn;
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("s")[0];
      let search_btn = document.getElementsByClassName("search_btn")[0];
      page.style.direction = "ltr";
      search_btn.classList.add("move-btn");
    }
  },
  methods: {
    openNav() {
      let nav = document.getElementsByClassName("mobile-nav")[0];
      if (nav.classList[1] != "show") {
        nav.classList.add("show");
      } else {
        nav.classList.remove("show");
      }
    },
    click(data) {
      console.log(data);
      console.log(this.$route.fullPath);
      let str = this.$route.fullPath;
      let link = "/" + data + str.slice(3);
      // console.log(link);
      this.$router.push(link);
      location.reload();
    },
    logout() {
      localStorage.clear();
      location.reload();
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.move-btn {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*********************** Header ***********************/
.s {
  z-index: 4000;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 4000;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: #1f2833;
  .logo,
  .links {
    display: flex;
    justify-content: center;
    width: calc(100% / 2);
  }
}
.logo {
  display: flex;
  align-items: center;
}
.logo img {
  width: 50px;
  height: 55px;
  transform: scale(1.5);
}
.logo span {
  margin: 1rem;
  color: #dfe0e2;
  font-size: 20px;
  font-weight: 700;
}

.links_list {
  display: flex;
  list-style: none;
}
.links_list .link {
  display: flex;
  align-items: center;
  margin: 1.2rem;
}
.link a {
  color: #dfe0e2;
  font-weight: 700;
  transition: all 250ms ease-in-out;
  i {
    font-size: 24px;
  }
}
.link a:hover {
  color: #472bff;
}
.logout {
  color: #dfe0e2;
  font-weight: 800;
  transition: all 250ms ease-in-out;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  font-size: 16px;
  &:hover {
    color: #472bff;
    cursor: pointer;
  }
}
.openMobileNavBtn {
  display: none;
}
.line {
  width: 35px;
  height: 3px;
  margin: 10px;
  background-color: #dfe0e2;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}
.openMobileNavBtn:hover {
  cursor: pointer;
  .line {
    background-color: #472bff;
  }
}
.mobile-nav {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 4000;
  ul {
    display: none;
    list-style: none;
  }
  ul li {
    text-align: center;
    opacity: 0;
    padding: 10px;
  }
  li a {
    font-weight: 800;
    color: #eeeeee;
    transition: all 0.35s ease-out;
    &:hover {
      color: #472bff;
    }
  }
  ul li a {
    display: none;
  }
}
.show {
  background-color: #1f2833;
  width: 100%;
  ul {
    display: block;
  }
  ul li {
    opacity: 1;
  }
  ul li a {
    display: block;
  }
}
.show_nav {
  background: none;
  border: none;
  outline: none;
  direction: rtl;
  display: none;
  width: 40px;
  height: 40px;
  div {
    margin: 9px;
    text-align: right;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 1200px) {
  .show {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .links_list {
    display: none;
  }
  .openMobileNavBtn {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .Links ul {
    display: none;
  }
  .show_nav {
    display: block;
  }
}
@media screen and (min-width: 700px) {
  .show {
    display: none;
  }
}
/*********************** Header ***********************/
</style>
