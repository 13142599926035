<template>
  <div class="home">
    <!--================= Header =================-->
    <div class="nav">
      <NavBar />
    </div>
    <!--================= Header =================-->
    <!--================= Welcome =================-->
    <div class="welcome_section">
      <div class="part-one">
        <div class="img2">
          <div class="img2_content">
            <h1 class="part-one-title">أزمات</h1>
            <div class="joinus">
              <p>انضم لنا و تسوق عن طريق منصتنا</p>
              <button class="goToShoBtn" v-on:click="goToShop()">
                الذهاب الى المتجر
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="part-two">
        <div>
          <h1 class="main-title">.خليك ببيتك مرتاح و اترك اغراضك علينا</h1>
          <h3 class="sub-title">.أزمات بتوصلك طلباتك لباب بيتك</h3>
        </div>
      </div>
    </div>
    <!--================= Welcome =================-->
    <!--================= Features =================-->
    <h1 class="section_title">مميزات أزمات</h1>
    <div class="features">
      <div class="container hidden">
        <div class="feature hidden">
          <div class="feature_icon">
            <i class="fa-solid fa-truck"></i>
          </div>
          <div class="feature_title">
            <h2>{{ $t("HomePage.Features.FeatureTitle1") }}</h2>
          </div>
          <!-- <div class="feature_content">
            {{ $t("HomePage.Features.FeatureContent1") }}
          </div> -->
        </div>
        <div class="feature hidden">
          <div class="feature_icon">
            <i class="fa-solid fa-dollar-sign"></i>
          </div>
          <div class="feature_title">
            <h2>{{ $t("HomePage.Features.FeatureTitle2") }}</h2>
          </div>
        </div>
        <div class="feature hidden">
          <div class="feature_icon">
            <i class="fa-solid fa-user-check"></i>
          </div>
          <div class="feature_title">
            <h2>{{ $t("HomePage.Features.FeatureTitle3") }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!--================= Features =================-->
    <!--================= onSale =================-->
    <h1 class="section_title">التخفيضات</h1>
    <div class="pro">
      <div class="products">
        <div
          class="product"
          v-for="product in topDiscountProducts"
          v-bind:key="product.id"
        >
          <div class="discount_rate">
            تخفيض : {{ product.discountPercent }} %
          </div>
          <div class="product_content">
            <router-link :to="`/${$i18n.locale}/Product/${product.id}`">
              <div class="product_img">
                <img :src="product.image" />
              </div>
              <div class="product_info">
                <span class="product_name">
                  {{ product.name }}
                </span>
                <span class="new_price">
                  {{
                    Math.floor(
                      product.price -
                        (product.discountPercent / 100) * product.price
                    )
                  }}
                  ₪
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--================= onSale =================-->
    <!--================= Contact us =================-->
    <h1 class="section_title">{{ $t("HomePage.Contactus.title") }}</h1>
    <div class="contactus">
      <div class="form">
        <div class="input">
          <input
            type="text"
            class="text-input"
            id="email_input"
            :placeholder="$t('HomePage.Contactus.input1')"
          />
        </div>
        <div class="input">
          <textarea
            class="msg_input"
            :placeholder="$t('HomePage.Contactus.input2')"
          ></textarea>
        </div>
        <div class="input">
          <button class="send_btn">
            <i class="fa-regular fa-paper-plane"></i>
            {{ $t("HomePage.Contactus.button") }}
          </button>
        </div>
      </div>
    </div>
    <!--================= Contact us =================-->
    <!--================= footer =================-->
    <footer>
      <div class="footer-content">
        <h3>{{ $t("HomePage.footer.title") }}</h3>
        <p>
          {{ $t("HomePage.footer.description") }}
        </p>
        <ul class="socials">
          <li>
            <a href="#"><i class="fa fa-facebook"></i></a>
          </li>
          <li>
            <a href="https://www.instagram.com/azmat.ps/" target="blank"
              ><i class="fa-brands fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="footer-bottom">
        <p>{{ $t("HomePage.footer.title") }} &copy; 2023</p>
      </div>
    </footer>
    <!--================= footer =================-->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ApiUrl from "../ApiUrl";
import axios from "axios";
export default {
  name: "HomeView",
  components: {
    NavBar,
  },
  data() {
    return {
      navbarOpen: false,
      topDiscountProducts: [],
    };
  },
  async mounted() {
    if (this.$i18n.locale == "en") {
      let home = document.getElementsByClassName("home")[0];
      let slideBtn1 = document.getElementsByClassName("fa-chevron-right")[0];
      let slideBtn2 = document.getElementsByClassName("fa-chevron-right")[1];
      let search_btn = document.getElementsByClassName("search_btn")[0];
      home.style.direction = "ltr";
      slideBtn1.classList.add("fa-rotate-180");
      slideBtn2.classList.remove("fa-rotate-180");
      search_btn.classList.add("move-btn");
    }
    await axios
      .get(ApiUrl.url + "/api/Products/topdiscounts", {
        headers: {
          "Accept-language": this.$i18n.locale,
        },
      })
      .then((res) => {
        this.topDiscountProducts = res.data;
      });
  },
  methods: {
    goToShop() {
      this.$router.push(`/${this.$i18n.locale}/Shop`);
    },
  },
};
function reveal1() {
  var reveals = document.querySelectorAll(".hidden");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("show1");
    } else {
      reveals[i].classList.remove("show1");
    }
  }
}
window.addEventListener("scroll", reveal1);
</script>

<style lang="scss" scoped>
.home {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
.nav {
  height: 80px;
}
/*********************** Lang class ***********************/

/*********************** Lang class ***********************/
/*********************** Welcome ***********************/
.welcome_section {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: space-between;
  direction: ltr;
}
.part-one {
  width: 50%;
  height: 100%;
}
.img2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #3721c5;
  clip-path: circle(88% at 1% 50%);
}
.img2_content {
  text-align: right;
}
.part-one-title {
  color: #fff;
}
.joinus p {
  color: #fff;
  font-size: 20px;
  padding-bottom: 1.2rem;
  color: rgb(168, 168, 168);
}
.goToShoBtn {
  font-family: "IBM Plex Sans Arabic", sans-serif;
  width: 200px;
  height: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  background: rgb(0, 186, 148);
  border-radius: 15px;
  transition: all 250ms ease-in-out;
  &:hover {
    cursor: pointer;
    background: rgb(0, 153, 122);
  }
}
.part-two {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-title {
  color: rgb(168, 168, 168);
  text-align: right;
}
/*********************** Welcome ***********************/
/*********************** Features ***********************/
.features {
  display: flex;
  justify-content: center;
}
.container {
  display: grid;
  grid-template-columns: auto auto auto;
}
.feature {
  width: 360px;
  height: 150px;
  border-radius: 30px;
  margin: 3.5rem;
  padding: 20px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}
.feature_icon {
  text-align: center;
  height: 50px;
  i {
    color: #472bff;
    font-size: 42px;
  }
}
.feature_title {
  text-align: center;
  margin: 1rem;
  height: 50px;
  transition: all 300ms ease-in-out;
}
.feature_content {
  display: flex;
  text-align: center;
  align-items: center;
  height: 100px;
  transition: all 300ms ease-in-out;
}
.feature:nth-child(1) {
  transition-delay: 200ms;
}
.feature:nth-child(2) {
  transition-delay: 400ms;
}
.feature:nth-child(3) {
  transition-delay: 600ms;
}
.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
}
.show1 {
  opacity: 1;
  filter: blur(0px);
  transform: translateX(0);
}
@media screen and (max-width: 1430px) {
  .container {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 1079px) {
  .container {
    grid-template-columns: auto;
  }
}
@media screen and (max-width: 480px) {
  .feature {
    width: 330px;
  }
}
/*********************** Features ***********************/

/*********************** On sale ***********************/
.section_title {
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  color: #1f2833;
  margin-top: 3.5rem;
}
.pro {
  margin-top: 3.5rem;
  display: flex;
  justify-content: center;
}
.products {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.product {
  margin: 1rem;
}
.discount_rate {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
  background: #472bff;
  display: inline-block;
  border-radius: 10px;
  padding: 5px;
  color: #fff;
}
.product_content {
  width: 350px;
  height: 350px;
  border-radius: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.product_img {
  height: 270px;
  img {
    width: 350px;
    height: 270px;
    max-width: 350px;
    max-height: 270px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
}
.product_info {
  height: 80px;
  display: flex;
  align-items: center;
  color: #1f2833;
}
.product_name {
  width: 250px;
  font-weight: 600;
  font-size: 18px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.new_price {
  width: 100px;
  font-weight: 600;
  font-size: 18px;
}

@media screen and (max-width: 1542px) {
  .products {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1160px) {
  .products {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 765px) {
  .products {
    grid-template-columns: auto;
  }
  .feature {
    margin: 2rem;
  }
}

/*********************** On sale ***********************/
/*********************** Contact us ***********************/
.contactus {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem;
}
.input {
  padding: 1rem;
}
.text-input {
  width: 500px;
  height: 40px;
  padding: 1rem;
  background-color: #dfe0e2;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
}
::placeholder {
  color: #1f2833;
}
.msg_input {
  width: 500px;
  height: 100px;
  max-height: 100px;
  min-width: 500px;
  max-width: 500px;
  background-color: #dfe0e2;
  border: none;
  color: #1f2833;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
}
.send_btn {
  width: 500px;
  height: 40px;
  background-color: #472bff;
  color: #eeeeee;
  border-radius: 10px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
@media screen and (max-width: 550px) {
  .msg_input {
    min-width: 365px;
    width: 365px;
  }
  .text-input,
  .send_btn {
    width: 365px;
  }
}
@media screen and (max-width: 390px) {
  .msg_input {
    min-width: 200px;
    width: 200px;
  }
  .text-input,
  .send_btn {
    width: 200px;
  }
}
/*********************** Contact us ***********************/
/*********************** footer ***********************/
footer {
  background: #111;
  height: auto;
  width: 100%;
  padding-top: 40px;
  color: #fff;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-content h3 {
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}
.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 3rem 0;
}
.socials li {
  margin: 0 10px;
}
.socials a {
  text-decoration: none;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #1f2833;
  border-radius: 5px;
}
.socials a i {
  font-size: 1.1rem;
  width: 20px;

  transition: color 0.4s ease;
}
.socials a:hover i {
  color: #472bff;
}

.footer-bottom {
  background: #1f2833;
  width: 100%;
  padding: 20px;
  padding-bottom: 40px;
  text-align: center;
}
.footer-bottom p {
  float: left;
  font-size: 14px;
  word-spacing: 2px;
}
.footer-bottom p a {
  color: #44bae8;
  font-size: 16px;
  text-decoration: none;
}
.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}
.footer-menu {
  float: right;
}
.footer-menu ul {
  display: flex;
}
.footer-menu ul li {
  padding-right: 10px;
  display: block;
}
.footer-menu ul li a {
  color: #cfd2d6;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
.footer-menu ul li a:hover {
  color: #472bff;
}
@media (max-width: 500px) {
  .footer-menu ul {
    display: flex;
    margin-top: 10px;
  }
}
/*********************** footer ***********************/
</style>
