<template>
  <div class="dashboard">
    <!--================= Dashboard =================-->
    <div>
      <h2 class="section_title">{{ $t("AdminPage.AdminDashboard.Title") }}</h2>
      <div class="wrapper">
        <div class="boxes">
          <div class="box">
            <div class="left_side">
              <i class="fa-solid fa-tags"></i>
            </div>
            <div class="right_side">
              <div class="box_title">
                {{ $t("AdminPage.AdminDashboard.Box1") }}
              </div>
              <div class="box_number">{{ categoriesNumber }}</div>
            </div>
          </div>
          <div class="box">
            <div class="left_side">
              <i class="fa-solid fa-cube"></i>
            </div>
            <div class="right_side">
              <div class="box_title">
                {{ $t("AdminPage.AdminDashboard.Box2") }}
              </div>
              <div class="box_number">{{ productsNumber }}</div>
            </div>
          </div>
          <div class="box">
            <div class="left_side">
              <i class="fa-regular fa-user"></i>
            </div>
            <div class="right_side">
              <div class="box_title">
                {{ $t("AdminPage.AdminDashboard.Box3") }}
              </div>
              <div class="box_number">{{ usersNumber }}</div>
            </div>
          </div>
          <div class="box">
            <div class="left_side">
              <i class="fa-regular fa-eye"></i>
            </div>
            <div class="right_side">
              <div class="box_title">
                {{ $t("AdminPage.AdminDashboard.Box4") }}
              </div>
              <div class="box_number">{{ views }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--================= Dashboard =================-->
    <!--================= Orders and Sales =================-->
    <div class="ordersAndSales">
      <div class="orders">
        <h2 class="section_title">
          {{ $t("AdminPage.AdminDashboard.Orders") }}
        </h2>
        <div class="orders_table">
          <div class="otable">
            <div class="first_order_row">
              <div class="order_no">
                {{ $t("AdminPage.AdminDashboard.OrdersTable.orderNo") }}
              </div>
              <div class="customer_name">
                {{ $t("AdminPage.AdminDashboard.OrdersTable.customerName") }}
              </div>
              <div class="phonenumber">
                {{ $t("AdminPage.AdminDashboard.OrdersTable.phoneNumber") }}
              </div>
              <div class="price">
                {{ $t("AdminPage.AdminDashboard.OrdersTable.price") }}
              </div>
              <div class="details">
                {{ $t("AdminPage.AdminDashboard.OrdersTable.details") }}
              </div>
            </div>
            <div
              class="order_row"
              v-for="order in orders"
              v-bind:key="order.orderNo"
            >
              <div class="order_no">{{ order.orderNo }}</div>
              <div class="customer_name">{{ order.user.name }}</div>
              <div class="phonenumber">{{ order.user.phone }}</div>
              <div class="price">{{ order.total }}</div>
              <div class="details_btn">
                <button class="view" v-on:click="viewOrder(order.orderNo)">
                  <i class="fa-regular fa-eye"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sales">
        <h2 class="section_title">
          {{ $t("AdminPage.AdminDashboard.Sales") }}
        </h2>
        <div class="sales_boxes">
          <div class="sale_box">
            <div class="sale_box_title">عدد الطلبات الكلي</div>
            <div class="sale_box_cont">300</div>
          </div>
          <div class="sale_box">
            <div class="sale_box_title">عدد الطلبات في الشهر</div>
            <div class="sale_box_cont">22</div>
          </div>
          <div class="sale_box">
            <div class="sale_box_title">اجمالي الدخل</div>
            <div class="sale_box_cont">3210</div>
          </div>
          <div class="sale_box last_sale_box">
            <div class="sale_box_title">
              <router-link to=""> المزيد -> </router-link>
            </div>
          </div>
        </div>
      </div>
      <!--================= Orders and Sales =================-->
    </div>
    <!--================= Orders and Sales =================-->
  </div>
</template>

<script>
import axios from "axios";
import ApiUrl from "../../ApiUrl";
export default {
  data() {
    return {
      orders: [],
      categoriesNumber: 0,
      productsNumber: 0,
      usersNumber: 0,
      views: 0,
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("dashboard")[0];
      let section_title = document.getElementsByClassName("section_title");
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    }
    await axios
      .get(ApiUrl.url + "/api/Orders/GetAll", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.orders = res.data;
      });
    await axios
      .get(ApiUrl.url + "/api/Categories/GetCount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.categoriesNumber = res.data;
      });
    await axios
      .get(
        ApiUrl.url +
          `/api/Products/filter?query=&categories=&minPrice=0&maxPrice=0&isCount=true`,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((res) => {
        this.productsNumber = res.data;
      });

    await axios
      .get(
        ApiUrl.url +
          `/api/Products/filter?query=&categories=&minPrice=0&maxPrice=0&isCount=true`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.usersNumber = res.data;
      });
  },
  methods: {
    viewOrder(orderNo) {
      this.$router.push(`/${this.$i18n.locale}/Admin/Order/${orderNo}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  direction: rtl;
}

/*********************** Dashboard ***********************/
.wrapper {
  display: flex;
  justify-content: center;
}
.boxes {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 40px;
}
.section_title {
  text-align: right;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.box {
  width: 320px;
  height: 120px;
  background-color: #1f2833;
  display: flex;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 5px solid #472bff;
}
.left_side {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100%;
  i {
    font-size: 32px;
    color: #472bff;
  }
}
.right_side {
  width: 200px;
  height: 100%;
  color: #eeeeee;
}
.box_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  font-size: 24px;
}
.box_number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  font-size: 24px;
  color: rgb(170, 170, 170);
}
/*********************** Dashboard ***********************/
/*********************** Lang Classes ***********************/
.left_text {
  text-align: left;
}
/*********************** Lang Classes ***********************/
/*********************** Orders and Sales  ***********************/
.ordersAndSales {
  display: flex;
  margin-top: 2rem;
  .orders,
  .sales {
    width: 50%;
  }
}
.orders_table {
  margin: 1rem;
}
.otable {
  background-color: #1f2833;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.1);
  height: 270px;
  overflow-y: auto;
}
.first_order_row {
  display: flex;
  align-items: center;
  height: 50px;
  .order_no {
    width: 30%;
    color: #eeeeee;
    font-weight: 600;
    padding: 10px;
  }
  .customer_name,
  .phonenumber,
  .price {
    color: #eeeeee;
    font-weight: 600;
    padding: 10px;
  }
  .customer_name {
    width: 20%;
  }
  .phonenumber {
    width: 20%;
  }
  .price {
    width: 10%;
  }
  .details {
    width: 20%;
    text-align: center;
    color: #eeeeee;
    font-weight: 600;
    padding: 10px;
  }
}
.order_row {
  display: flex;
  align-items: center;
  height: 50px;
  .order_no {
    width: 30%;
    color: rgb(170, 170, 170);
    font-weight: 600;
    padding: 10px;
  }
  .phonenumber,
  .customer_name,
  .price {
    color: rgb(170, 170, 170);
    font-weight: 600;
    padding: 10px;
  }
  .customer_name {
    width: 20%;
  }
  .phonenumber {
    width: 20%;
  }
  .price {
    width: 10%;
  }
  .details_btn {
    color: rgb(170, 170, 170);
    font-weight: 600;
    padding: 10px;
    width: 20%;
    text-align: center;
    .view {
      background-color: #472bff;
      color: #eeeeee;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      transition: all 300ms ease-in-out;
      &:hover {
        cursor: pointer;
        color: #472bff;
        background-color: #eeeeee;
      }
    }
  }
}
.sales_boxes {
  display: grid;
  grid-template-columns: auto auto;
  margin: 1rem;
  gap: 40px;
}
.sale_box {
  width: 330px;
  height: 100px;
  background-color: #1f2833;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom: 3px solid #472bff;
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  color: #eeeeee;
  font-size: 24px;
  font-size: bold;
  .sale_box_title {
    width: 250px;
    padding: 10px;
  }
  .sale_box_cont {
    width: 50px;
    color: rgb(170, 170, 170);
  }
}
.last_sale_box {
  display: flex;
  text-align: center;
  .sale_box_title {
    width: 330px;
    padding: 10px;
  }
}
/*********************** Orders and Sales  ***********************/
</style>
