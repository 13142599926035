<template>
  <div class="userOrders">
    <div class="a">
      <div class="nav">
        <NavBar />
      </div>
      <div class="v">
        <h2 class="section_title">{{ $t("UserOrders.Title") }}</h2>
        <div class="o">
          <div class="orders">
            <div
              class="order"
              v-for="order in orders"
              v-bind:key="order.orderNo"
            >
              <div class="order_info">
                <h3>{{ $t("UserOrders.OrderNo") }} {{ order.orderNo }}</h3>
                <h3>|</h3>
                <h3 v-if="order.status.name == 'Pending'">
                  {{ $t("UserOrders.OrderStatus") }}
                  {{ $t("UserOrders.status1") }}
                </h3>
                <h3 v-if="order.status.name == 'On the way'">
                  {{ $t("UserOrders.OrderStatus") }}
                  {{ $t("UserOrders.status2") }}
                </h3>
                <h3 v-if="order.status.name == 'Done'">
                  {{ $t("UserOrders.OrderStatus") }}
                  {{ $t("UserOrders.status3") }}
                </h3>
                <h3 v-if="order.status.name == 'Failed'">
                  {{ $t("UserOrders.OrderStatus") }}
                  {{ $t("UserOrders.status4") }}
                </h3>
              </div>
              <div class="order_date">
                {{ order.date.split("T")[0] }}
              </div>
              <div class="products">
                <div
                  class="product"
                  v-for="product in order.products"
                  v-bind:key="product.productID"
                >
                  <div class="proudct_image">
                    <img :src="product.image" />
                  </div>
                  <div class="product_info">
                    <div class="product_name">
                      <h3>
                        {{ $t("UserOrders.ProductName") }} {{ product.name }}
                      </h3>
                    </div>
                    <div class="product_qty">
                      <h4>
                        {{ $t("UserOrders.quantity") }} {{ product.quantity }}
                      </h4>
                    </div>
                    <div class="product_price">
                      <h4>{{ $t("UserOrders.price") }} {{ product.price }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="total_price">
                <h3>{{ $t("UserOrders.totalPrice") }} {{ order.total }} ₪</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Footer">
      <footerUser />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import footerUser from "@/components/footerUser.vue";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
export default {
  components: {
    NavBar,
    footerUser,
  },
  data() {
    return {
      orders: [],
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    let page = document.getElementsByClassName("manageOrders")[0];
    let section_title = document.getElementsByClassName("section_title");
    if (this.$i18n.locale == "en") {
      page.style.direction = "ltr";
      for (let i = 0; i < section_title.length; i++) {
        section_title[i].classList.add("left_text");
      }
    }
    this.loadOrders();
  },
  methods: {
    async loadOrders() {
      await axios
        .get(ApiUrl.url + "/api/Orders", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res);
          this.orders = res.data;
        })
        .catch((err) => {
          if (err) {
            this.orders = [];
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.userOrders {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.a {
  flex: 1;
}
.nav {
  height: 80px;
}
.section_title {
  text-align: right;
  margin: 1.5rem;
  margin-bottom: 1rem;
}
.left_text {
  text-align: left;
}
.v {
  margin: 1rem;
}
.o {
  display: flex;
  margin: 3rem;
}
.order {
  padding: 3rem;
}
.order:first-child {
  padding-top: 0rem;
}
.order_info {
  display: flex;
  text-align: right;
}
.product {
  display: flex;
  margin-top: 1rem;
}
.proudct_image {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 300px;
  }
}
.product_info {
  margin-left: 1rem;
  margin-right: 1rem;
}
.total_price {
  margin-top: 1.2rem;
}
</style>
