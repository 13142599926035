<template>
  <div class="updatename">
    <!--================= Form =================-->
    <div class="form">
      <form method="post" @submit.prevent="updateName()">
        <h2>{{ $t("ProfilePage.UserSettings.UpdateNamePage.title") }}</h2>
        <div class="input">
          <input
            type="text"
            id="name_input"
            :placeholder="
              $t('ProfilePage.UserSettings.UpdateNamePage.placeholder')
            "
            class="text-input"
            v-model="name"
          />
          <br />
          <label for="name_input" class="name_input_label"></label>
        </div>
        <div class="input">
          <button class="update_name_btn">
            {{ $t("ProfilePage.UserSettings.UpdateNamePage.UpdateBtn") }}
          </button>
          <div class="input">
            <span class="update_success"></span>
          </div>
        </div>
      </form>
    </div>
    <!--================= Form =================-->
  </div>
</template>

<script>
import jquery from "jquery";
import axios from "axios";
import ApiUrl from "../../ApiUrl";
let $ = jquery;
export default {
  data() {
    return {
      name: "",
    };
  },
  async mounted() {
    this.$store.dispatch("verifyToken");
    if (this.$i18n.locale == "en") {
      let page = document.getElementsByClassName("updatename")[0];
      page.style.direction = "ltr";
    }
  },
  methods: {
    async updateName() {
      $("#name_input").click(function () {
        window.onkeypress = function () {
          $("#name_input").css("borderBottom", "3px solid #472bff");
          $(".name_input_label").text("");
        };
      });
      if (this.name == "") {
        $("#name_input").css("borderBottom", "3px solid rgb(250,82,82)");
        $(".name_input_label").text(
          this.$i18n.t("ProfilePage.UserSettings.UpdateNamePage.err1")
        );
        return;
      }
      await axios
        .post(
          ApiUrl.url + "/api/User/updateName",
          { Name: this.name },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            $(".add_success").text(
              this.$i18n.t(
                "ProfilePage.UserSettings.UpdateNamePage.success_msg1"
              )
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.updatename {
  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;
}
/*********************** Form ***********************/
.form {
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
  }
}
.input {
  padding: 1rem;
}
.text-input {
  width: 365px;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  border-bottom: 3px solid #472bff;
  transition: all 300ms ease-in-out;
}
.update_name_btn {
  width: 365px;
  height: 40px;
  background-color: #472bff;
  border-radius: 10px;
  color: #eeeeee;
  font-size: 16px;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #3721c5;
  }
}
.name_input_label {
  color: rgb(250, 82, 82);
}
.input:last-child {
  text-align: center;
}
/*********************** Form ***********************/
</style>
